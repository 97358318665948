<template>
	<div>
		<!-- contenedor del objeto map extraido de la api de Google -->
		<div class="google-map" ref="googleMap"></div>
		<!--  -->
		<template v-if="Boolean(this.google) && Boolean(this.map)">
			<slot :google="google" :map="map" />
		</template>
	</div>
</template>

<script>
import GoogleMapsApiLoader from "@/plugins/maps";

export default {
	data() {
		return {
			google: null,
			map: null
		};
	},

	async mounted() {
		const googleMapApi = await GoogleMapsApiLoader;
		//Instancia de Google Maps Api
		this.google = googleMapApi;
		this.initializeMap();
	},

	methods: {
		initializeMap() {
			const mapContainer = this.$refs.googleMap;
			const mapConfig = {
				center: { lat: 21.1214886, lng: -86.9192742 },
				zoom: 8,
				streetViewControl: false
			};
			//Instancia de la instancia de Google Maps Api... Este es el mapa
			this.map = new this.google.maps.Map(mapContainer, mapConfig);
		}
	}
};
</script>

<style scoped>
.google-map {
	width: 100%;
	min-height: 100%;
}
</style>
