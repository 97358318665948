import TrackingPortal from "@/views/tracking/TrackingPortal";
export default [
	{
		path: "/tracking/:serviceId",
		component: TrackingPortal,
		name: "Tracking",
		meta: {
			requiresAuth: true
		}
	}
];
