<template>
	<v-dialog width="92%" max-width="680" :value="value" @input="close">
		<v-card
			min-height="70vh"
			class="rounded-lg d-flex flex-column justify-space-between background-menu"
		>
			<BtnClose @click="close" />
			<div>
				<v-container>
					<v-container class="mt-5  d-flex flex-row">
						<div>
							<div class="font-weight-bold text-h6">
								{{ $t("global.hi") }}
							</div>
							<div class="font-weight-bold subtitle-2">
								{{ clientName }}
							</div>
						</div>
						<v-spacer></v-spacer>
						<v-btn fab elevation="0" color="primary100">
							<v-icon size="50" color="primary">face</v-icon>
						</v-btn>
					</v-container>
				</v-container>
				<v-container>
					<v-row dense>
						<v-col
							cols="6"
							v-for="(service,
							i) of reservationModel.transportServices"
							:key="i"
						>
							<v-card
								elevation="0"
								class="rounded-lg"
								color="primary20"
								@click="openCheckin(service)"
							>
								<v-container class="my-0 py-2 d-flex flex-row">
									<v-icon
										v-if="service.locked === 'client'"
										color="green"
										class="ml-n1 mr-1"
										>check</v-icon
									>
									<v-icon v-else class="ml-n1 mr-1"
										>edit_note</v-icon
									>
									<div
										class="d-flex flex-column align-center"
									>
										<div class="body-2">
											{{ $t("global.myReservation") }}
										</div>
										<div
											class="subtitle-2 primary--text font-weight-bold"
										>
											ID {{ service.serviceNumber }}
										</div>
									</div>
								</v-container>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</div>
			<v-list dense>
				<v-list-item @click="dialogHelp = true">
					<v-list-item-icon class="mr-2">
						<v-icon>support_agent</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t("global.callCenter")
						}}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-icon color="primary">navigate_next</v-icon>
					</v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-btn-toggle
							dense
							borderless
							tile
							color="primary"
							class="ml-n2"
							:value="lang"
						>
							<v-btn
								@click="changeLang('es')"
								value="es"
								color="white"
							>
								<v-icon
									class="mr-2"
									:color="lang == 'es' ? 'primary' : ''"
								>
									language
								</v-icon>
								<span>ES</span>
							</v-btn>
							<v-btn
								@click="changeLang('en')"
								value="en"
								color="white"
							>
								<v-icon
									class="mr-2"
									:color="lang == 'en' ? 'primary' : ''"
									v
								>
									language
								</v-icon>
								<span>EN</span>
							</v-btn>
						</v-btn-toggle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="_logout">
					<v-list-item-icon class="mr-2">
						<v-icon>login</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t("global.logout")
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
		<DialogHelp v-model="dialogHelp" @close="close" />
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
import DialogHelp from "@/components/dialog/DialogHelp";
import { mapState, mapActions } from "vuex";
export default {
	components: {
		BtnClose,
		DialogHelp
	},
	props: {
		value: Boolean
	},
	data: () => ({
		dialogHelp: false
	}),
	computed: {
		...mapState("reservation", ["reservationModel"]),
		...mapState("lang", ["lang"]),
		clientName: vm => vm.reservationModel.client?.fullName || ""
	},
	methods: {
		...mapActions("lang", ["_changeLang"]),
		...mapActions("auth", ["_logout"]),
		...mapActions("service", ["_openCheckin"]),
		close() {
			this.$emit("input", false);
			this.$emit("close");
		},
		changeLang(lang) {
			this._changeLang(lang);
		},
		openCheckin(service) {
			this.close();
			this._openCheckin(service.id);
		}
	}
};
</script>

<style scoped>
::v-deep .v-dialog {
	/*   bottom: 0;
  position: fixed; */
}
.background-menu {
	background-image: url("~@/assets/menuBackground.png");
	background-attachment: initial;
	background-repeat: no-repeat;
	background-position: center;
}
::v-deep .theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
	opacity: 0;
}
</style>
