import camelCase from "lodash/camelCase";
import axios from "@/plugins/axios";

const files = require.context("./api", false, /\.js$/);

// Obtiene el nombre de un archivo js
const basename = (path, extension = true) => {
	const name = path.split(/[\\/]/).pop();

	if (!extension) {
		return name.split(".").shift();
	}

	return name;
};
const getActionMethod = (action, service) => {
	switch (action) {
		case "show":
			return (id, options) =>
				axios
					.get(`${service.resource}/${id}`, options)
					.then(response => response.data.data);

		case "index":
			return (params = {}, options = {}) =>
				axios
					.get(service.resource, { params, ...options })
					.then(response => response.data);

		case "create":
			return (data = {}) =>
				axios
					.post(service.resource, data)
					.then(response => response.data);

		case "update":
			return (id, data = {}) =>
				axios
					.put(`${service.resource}/${id}`, data)
					.then(response => response.data);

		case "patch":
			return (id, data = {}) =>
				axios
					.patch(`${service.resource}/${id}`, data)
					.then(response => response.data);

		case "destroy":
			return id => axios.delete(`${service.resource}/${id}`);

		default:
			return null;
	}
};
const processApiService = service => {
	if (!service.actions) return service;

	service.actions.forEach(action => {
		service[action] = getActionMethod(action, service);
	});

	return service;
};

const services = {};

files.keys().forEach(key => {
	const filename = camelCase(basename(key, false));

	services[filename] = processApiService(files(key).default);
});

// Exporta los servicios de forma automatica en funcion del nombre de los archivos de forma que si
// se crea el archivo "./api/consultation-type.js" se exportara como "consultationType"
export default services;
