// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/database";
const firebaseConfig = {
	apiKey: process.env.VUE_APP_FB_API_KEY,
	projectId: process.env.VUE_APP_FB_PROJECT_ID,
	messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_FB_APP_ID
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
const database = firebase.database();
auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
let messaging = null;
try {
	messaging = firebase.messaging();
} catch (err) {
	console.log(err);
}
export { messaging, auth, firestore, database, firebase };
