import { SET_MODEL } from "./constants";
import { api } from "@/service/api.service";
import router from "@/router";
export default {
	_fetchReservation: async ({ commit, state, rootState, dispatch }) => {
		const response = await api.get("/travelers/reservation", {
			skipLoading: false
		});
		if (response) {
			const reservation = {
				...response.data,
				transportServices: response.data.transportServices.sort(a =>
					a.trip === "A2H" ? -1 : 1
				)
			};
			commit(SET_MODEL, reservation);

      // Se identifica el cliente y la reserva en Hotjar
      try {
        const userId = reservation.client.id
        const reservationId = reservation.id
        window.hj('identify', userId, {
          reservationId
        })
      } catch (error) {
        console.log('hj identify failed ', error.message)
      }
      //

			await dispatch("market/_fetchProducts", null, { root: true });
			//Cuando se inicia desde el formulario de login no se tiene acceso al editToken
			//entonces, una vez obtenida se setea a la url para futuros recargas
			if (!rootState.auth.editToken) {
				commit("auth/EDIT_TOKEN", state.reservationModel.editToken, {
					root: true
				});
				router.replace({
					path: "/",
					query: {
						token: state.reservationModel.editToken
					}
				});
			}
		}
	}
};
