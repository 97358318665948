<template>
	<div>
		<v-toolbar
			:color="color ? color : dark ? 'primary' : 'primary100'"
			flat
			:dark="dark"
			class="mb-n3 rounded-t-lg d-flex justify-center"
			dense
			><div
				:class="
					`font-weight-bold mt-n3 ${
						textColor ? textColor : dark ? '' : 'primary--text'
					}`
				"
			>
				{{ title }}
			</div>
			<v-icon
				:disabled="disabledEdit"
				v-if="edit"
				@click="toRoute"
				:color="!dark ? 'primary' : 'white'"
				class="icon-edit"
				>edit_note</v-icon
			>
		</v-toolbar>
		<slot />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	props: {
		title: String,
		dark: Boolean,
		edit: String,
		disabledEdit: Boolean,
		serviceId: Number,
		color: String,
		textColor: String
	},
	computed: {
		...mapGetters("service", ["getBasePath"])
	},
	methods: {
		...mapActions("service", ["_setIdService"]),
		async toRoute() {
			if (this.serviceId)
				await this._setIdService({ serviceId: this.serviceId });
			this.$router.push(this.getBasePath(this.edit));
		}
	}
};
</script>
<style lang="scss" scoped>
.icon-edit {
	position: fixed;
	right: 5px;
	top: 5px;
}
</style>
