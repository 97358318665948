<template>
	<v-card
		class="rounded-t-lg mt-4"
		outlined
		:style="styleObject"
		:ref="'service' + service.id"
	>
		<v-toolbar flat dense>
			<v-toolbar-title class="font-weight-black"
				>SERVICE #{{ service.serviceNumber }}
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-tooltip
				color="primary"
				:disabled="!showTooltip"
				v-model="showTooltip"
				v-if="!isClientConfirmed(service)"
				bottom
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						elevation="0"
						class="font-weight-bold mr-n3"
						small
						:dark="darkStyle"
						:color="darkStyle ? 'primary' : 'primary100'"
						rounded
						@click="toRoute(service)"
						>{{ $t("global.btnEditOrConfirm") }}
						<v-icon small class="ml-1">edit_note</v-icon></v-btn
					>
				</template>
				<span
					class="d-flex text-center white--text subtitle-2 font-weight-bold"
					>{{ $t("global.tooltipEdit") }}</span
				>
			</v-tooltip>
			<v-btn
				elevation="0"
				@click="toRoute(service)"
				small
				rounded
				v-else
				color="green"
				class="font-weight-bold mr-n1"
				dark
				>{{ $t("global.confirm")
				}}<v-icon small class="ml-1">done</v-icon></v-btn
			>
		</v-toolbar>
		<v-row>
			<v-col
				cols="12"
				class="d-flex justify-center secondary--text font-weight-bold text-h6 mt-2 mb-n3"
			>
				<div>{{ clientName }}</div>
			</v-col>
			<v-col cols="12">
				<LayoutBase
					:dark="darkStyle"
					:title="t('pickupDropOff.reservationNumber')"
				>
					<CardReservationNumber :service="service" confirm-mode />
				</LayoutBase>
			</v-col>
			<v-col cols="12">
				<LayoutBase :dark="darkStyle" :title="t('pickupDropOff.title')">
					<LayoutCard
						@click="scrollEdit(service)"
						icon="hail"
						:title="t('pickupDropOff.form.pickup.location')"
						>{{ pickupfullName(service) }}</LayoutCard
					>
					<LayoutCard
						@click="scrollEdit(service)"
						class="mt-3"
						icon="location_on"
						:title="t('pickupDropOff.form.dropOff.location')"
						>{{ dropofffullName(service) }}</LayoutCard
					>
				</LayoutBase>
			</v-col>
			<v-col cols="12" v-if="existAirport(service)">
				<LayoutBase :dark="darkStyle" :title="t('flight.title')">
					<LayoutCard
						@click="scrollEdit(service)"
						icon="airplane_ticket"
						:title="t('flight.form.flightNumber')"
						>{{ service.flightNumber }}
						{{ airline(service) }}</LayoutCard
					>
					<LayoutDateTime
						@click="scrollEdit(service)"
						class="mt-3"
						:date="service.flightDatetime"
						:dateTitle="t(flightDateTitle(service))"
						:timeTitle="t(flightTimeTitle(service))"
					/>
				</LayoutBase>
			</v-col>
			<v-col cols="12" class="mb-1" v-if="!isArrival(service)">
				<LayoutBase :dark="darkStyle" :title="t('schedule.title')">
					<LayoutDateTime
						@click="scrollEdit(service)"
						:date="service.pickupDatetime"
						:dateTitle="t('schedule.pickupDate')"
						:timeTitle="t('schedule.pickupTime')"
					/>
				</LayoutBase>
			</v-col>
			<v-col cols="12" class="mb-n1">
				<LayoutBase
					:dark="darkStyle"
					:title="t('passenger.titleConfirm')"
				>
					<CardTransferType
						@click="scrollEdit(service)"
						:serviceType="service.serviceType.name"
						:adults="reservationModel.adults"
						:children="reservationModel.children"
						:infants="reservationModel.infants"
						confirm-mode
						dense
					/>
				</LayoutBase>
			</v-col>
			<v-col cols="12" class="mb-2">
				<LayoutBase
					:dark="darkStyle"
					:title="t('confirmation.form.specialRequest')"
				>
					<v-card elevation="0" width="100%" class="rounded-lg">
						<v-textarea
							height="100%"
							readonly
							hide-details=""
							dense
							:value="service.clientNote"
							outlined
							@click="scrollEdit(service)"
							:placeholder="
								t('confirmation.form.specialRequestPlaceholder')
							"
						></v-textarea>
					</v-card>
				</LayoutBase>
				<div class="mx-3 mt-1 caption font-weight-thin">
					{{ t("confirmation.form.specialRequestMessage") }}
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import LayoutBase from "@/layouts/LayoutBase";
import LayoutCard from "@/layouts/LayoutCard";
import LayoutDateTime from "@/layouts/LayoutDateTime";
import CardTransferType from "@/components/card/CardTransferType";
import CardReservationNumber from "@/components/card/CardReservationNumber";
import { mapActions, mapState } from "vuex";
export default {
	components: {
		LayoutBase,
		LayoutCard,
		LayoutDateTime,
		CardTransferType,
		CardReservationNumber
	},
	props: {
		service: Object,
		darkStyle: Boolean
	},
	data: () => ({
		styleObject: { border: "1px solid #4F39CF" },
		showTooltip: false
	}),
	computed: {
		...mapState("reservation", ["reservationModel"]),
		clientName: vm => vm.reservationModel?.client?.fullName || ""
	},
	methods: {
		...mapActions("service", ["_openCheckin"]),
		airline: service => service.flightValidationData?.airline || "",
		pickupfullName: service => service.pickup?.fullName || "",
		dropofffullName: service => service.dropoff?.fullName || "",
		isClientConfirmed(service) {
			return service.locked === "client";
		},

		async toRoute(service) {
			this._openCheckin(service.id);
		},
		isArrival(service) {
			return service.pickup?.isAirport ? true : false;
		},
		existAirport(service) {
			return service.pickup?.isAirport || service.dropoff?.isAirport;
		},
		scrollEdit(service) {
			this.$vuetify.goTo(this.$refs["service" + service.id]);
			setTimeout(() => (this.showTooltip = true), 1000);
		},
		flightDateTitle: service =>
			service.pickup?.isAirport
				? "flight.form.arrivalDate"
				: "flight.form.takeoffDate",
		flightTimeTitle: service =>
			service.pickup?.isAirport
				? "flight.form.arrivalTime"
				: "flight.form.takeoffTime",
		t(key, options = {}) {
			return this.$t(`section.${key}`, options);
		}
	}
};
</script>

<style></style>
