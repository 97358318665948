<template>
	<v-btn class="btn-close" icon color="secondary" @click="closed"
		><v-icon>close</v-icon></v-btn
	>
</template>

<script>
export default {
	methods: {
		closed() {
			this.$emit("click");
		}
	}
};
</script>

<style lang="scss" scoped>
.btn-close {
	position: absolute;
	top: 1%;
	right: 1%;
}
</style>
