<template>
	<div>
		<div class="primary--text subtitle-2 ml-2">{{ title }}</div>
		<v-text-field
			class="rounded-lg"
			ref="input"
			:messages="showMessage"
			dense
			outlined
			:rules="rules"
			:placeholder="showPlaceholder"
			:readonly="disabled"
			:value="value"
			@focus="$emit('focus')"
			@update:error="error = $event"
			@input="$emit('input', $event)"
		>
			<template v-slot:message>
				<div v-if="showMessage" :class="messageClass">
					{{ showMessage }}
				</div>
			</template>
			<template v-slot:prepend-inner>
				<v-icon class="material-icons-outlined">{{
					prependInnerIcon
				}}</v-icon>
			</template>
			<template v-slot:append>
				<slot name="append">
					<v-icon
						v-if="!iconAppend"
						:color="icon.color"
						class="material-icons-outlined"
						>{{ icon.icon }}</v-icon
					>
					<v-icon
						v-else
						:color="iconAppendColor"
						class="material-icons-outlined"
						>{{ iconAppend }}</v-icon
					>
				</slot>
			</template>
		</v-text-field>
	</div>
</template>

<script>
export default {
	props: {
		value: String,
		title: String,
		prependInnerIcon: String,
		iconAppend: String,
		iconAppendColor: String,
		messages: String,
		disabled: Boolean,
		placeholder: String,
		placeholderRules: {
			type: Boolean,
			default: true
		},
		messageClass: String,
		rules: {
			type: Array,
			default: function() {
				return [v => !!v || this.$t("global.required")];
			}
		}
	},
	data: () => ({
		error: false
	}),

	computed: {
		showMessage: vm => (!vm.error ? vm.messages : undefined),
		showPlaceholder: vm => {
			if (vm.placeholderRules)
				if (vm.error) {
					return vm.rules.map(x => x())[0];
				}
			return vm.placeholder;
		},
		icon: vm =>
			!vm.error
				? vm.value
					? { icon: "check_circle", color: "green" }
					: { icon: "check_circle", color: "grey" }
				: { icon: "error_outline", color: "red" }
	}
};
</script>
