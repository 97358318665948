<template>
	<v-card
		outlined
		:style="disabled ? 'opacity: 0.3' : ''"
		color="primary"
		class="rounded-xl"
		height="38px"
	>
		<v-btn-toggle class="ma-0" borderless color="primary" dense rounded>
			<v-btn
			  :disabled="disabled"
				color="white"
				@click="decrement"
			>
				<v-icon color="primary">remove</v-icon>
			</v-btn>
			<v-divider style="z-index:3" class="my-1" vertical></v-divider>
			<v-btn :disabled="disabled" color="white" :ripple="false">
				{{ value }}
			</v-btn>
			<v-divider style="z-index:3" class="my-1" vertical></v-divider>
			<v-btn
				:disabled="disabled"
				color="white"
				@click="increment"
			>
				<v-icon color="primary">add</v-icon>
			</v-btn>
		</v-btn-toggle>
	</v-card>
</template>

<script>
export default {
	props: {
		value: [Number, String],
		disabled: Boolean,
		disabledIncrement: Boolean,
		disabledDecrement: Boolean,
		minimun: {
			type: Number,
			default: 0
		}
	},
	mounted() {
		if (!this.value) this.$emit("input", 0);
	},
	methods: {
		increment() {
      if(!this.disabledIncrement) {
        this.$emit("input", this.value + 1);
      } else {
        this.$emit('disabled', 'increment')
      }
		},
		decrement() {
      if(!this.disabledDecrement) {
			  if (this.value === this.minimun) return;
			  this.$emit("input", this.value - 1);
      } else {
        this.$emit('disabled', 'decrement')
      }
		}
	}
};
</script>

<style></style>
