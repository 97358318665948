<template>
	<div class="fill-height d-flex align-center justify-center">
		<v-container class="d-flex flex-column text-center">
			<v-icon class="material-icons-outlined" size="60" color="success"
				>check_circle</v-icon
			>
			<span class=" my-4 title font-weight-bold">{{ t("title") }}</span>
			<p class="mx-3">{{ t("description") }}</p>
		</v-container>

		<v-footer class="max-width-viewport-container" color="white" elevation="3" app>
			<v-container class="d-flex flex-row justify-space-between">
				<v-btn
					elevation="0"
					block
					class="text-none font-weight-bold"
					color="primary"
					rounded
					@click="home"
				>
					<span class="mx-3">{{ t("btnBack") }}</span>
				</v-btn>
			</v-container>
		</v-footer>
	</div>
</template>

<script>
export default {
	components: {},
	activated() {
		this.$setBackRoute();
	},
	methods: {
		home() {
			this.$router.push({
				name: "Travelers"
			});
		},
		t(key, options = {}) {
			return this.$t(`market.product.success.${key}`, options);
		}
	}
};
</script>

<style></style>
