<template>
	<v-dialog max-width="300px" :value="value" @input="close">
		<v-card
			min-height="250px"
			class=" d-flex flex-column justify-space-around  align-center"
		>
			<BtnClose @click="close" />

			<v-card-title class="mt-2">
				<v-icon color="red" class="material-icons-outlined" size="60"
					>lock</v-icon
				>
			</v-card-title>

			<v-container
				class="d-flex flex-column  align-center subtitle-2 justify-space-between mb-n4"
			>
				<div class="font-weight-bold">{{ t("locked.title") }}</div>
				<div class="text-center my-4">
					{{ message }}
				</div>
				<div class="font-weight-bold">{{ t("locked.subtitle") }}</div>
			</v-container>

			<ShuttleCentralContact />
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
import ShuttleCentralContact from "@/components/ShuttleCentralContact";
import { mapGetters } from "vuex";
export default {
	components: {
		ShuttleCentralContact,
		BtnClose
	},
	data: () => ({
		style: "font-weight-medium text-decoration-underline primary--text mt-3"
	}),
	props: {
		value: Boolean,
		title: String,
		type: {
			type: String,
			default: "success"
		}
	},
	computed: {
		...mapGetters("service", [
			"inputDisableType",
			"getSalesChannel",
			"isServiceShare"
		]),
		message: vm => {
			if (vm.inputDisableType)
				return vm.t("locked." + vm.inputDisableType, {
					channel: vm.getSalesChannel
				});
			if (vm.isServiceShare) return vm.t("locked.privateservice");
			return "";
		}
	},
	methods: {
		close() {
			this.$emit("input", false);
			this.$emit("close");
		},
		t(key, options = {}) {
			return this.$t(`info.${key}`, options);
		}
	}
};
</script>
