import axios from "@/plugins/axios";
export default {
	get: params =>
		axios
			.get("/travelers/additional-services", { params })
			.then(response => response.data),
	checkout: data =>
		axios
			.post("/travelers/additional-services/checkout", data)
			.then(response => response.data),
	convertCurrency: (id, params) =>
		axios
			.get(`/travelers/additional-services/${id}/exchange`, { params })
			.then(response => response.data)
};
