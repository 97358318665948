<template>
	<v-row dense class="justify-center">
		<v-col
			v-if="editionLocked"
			cols="12"
			class="d-flex flex-column justify-center align-center mb-5"
		>
			<v-btn text color="red" class="font-weight-bold" @click="openInfo"
				>{{ $t("global.btnLocked") }}<v-icon>lock</v-icon></v-btn
			>
		</v-col>
		<v-col cols="6" sm="4" md="3" lg="2" v-if="edit && !editionLocked"
			><v-btn
				color="primary"
				:disabled="disabledEdit"
				block
				rounded
				outlined
				@click="$emit('click-edit')"
				>{{ edit }}</v-btn
			></v-col
		>
		<v-col cols="6" sm="4" md="3" lg="2" v-if="confirm"
			><v-btn
				color="primary"
				:disabled="disabledConfirm"
				block
				rounded
				@click="$emit('click-confirm')"
				>{{ confirm }}</v-btn
			></v-col
		>
	</v-row>
</template>

<script>
export default {
	props: {
		edit: String,
		confirm: String,
		disabledConfirm: Boolean,
		disabledEdit: Boolean,
		editionLocked: Boolean
	},
	methods: {
		openInfo() {
			this.$root.$children[0].locked = true;
		}
	}
};
</script>
