<template>
	<v-menu>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				:color="color"
				elevation="0"
				class=" font-weight-black px-0"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon :color="iconColor">language</v-icon>
				{{ lang }}
				<v-icon :color="iconColor">arrow_drop_down</v-icon>
			</v-btn>
		</template>

		<v-list dense class="my-n2">
			<v-list-item @click="_changeLang('es')">
				<v-list-item-title>ES</v-list-item-title>
			</v-list-item>
			<v-list-item @click="_changeLang('en')">
				<v-list-item-title>EN</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
	props: {
		dark: Boolean,
		color: {
			type: String,
			default: "white"
		}
	},
	computed: {
		...mapState("lang", ["lang"]),
		iconColor: vm => (vm.dark ? "white" : "primary")
	},
	methods: {
		...mapActions("lang", ["_changeLang"])
	}
};
</script>
