<template>
	<v-app-bar class="max-width-viewport-container" flat :app="propApp" :color="color">
		<v-col class="ma-0 pa-0">
			<BtnHelp
				outlined
				small
				:dark="dark"
				class="ml-n3"
				v-if="!toPage && !disableHelp"
				:color="color"
			/>
			<v-btn v-show="toPage" icon :color="color" :to="toPage"
				><v-icon :color="dark ? 'white' : 'primary'"
					>arrow_back</v-icon
				></v-btn
			>
		</v-col>
		<v-spacer></v-spacer>
		<v-col class="justify-center">
			<v-img
				v-if="!dark"
				max-width="220px"
				height="auto"
				:src="require('../assets/logoAppBarPrimary.svg')"
			></v-img>
			<v-img
				v-else
				max-width="220px"
				height="auto"
				:src="require('../assets/logoAppBarWhite.svg')"
			></v-img>
		</v-col>
		<v-spacer></v-spacer>
		<v-col class="ma-0 pa-0 d-flex flex-row justify-end">
			<BtnLanguage :dark="dark" v-if="!disableLang" :color="color" />
			<v-btn icon :color="color" v-if="!disableMenu" @click="menu = !menu"
				><v-icon :color="dark ? 'white' : 'primary'"
					>menu</v-icon
				></v-btn
			>
		</v-col>
		<DialogMenu v-model="menu" />
	</v-app-bar>
</template>

<script>
import BtnHelp from "@/components/btn/BtnHelp";
import BtnLanguage from "@/components/btn/BtnLanguage";
import DialogMenu from "@/components/dialog/DialogMenu";
import { mapGetters } from "vuex";
export default {
	components: {
		BtnHelp,
		BtnLanguage,
		DialogMenu
	},
	props: {
		toPage: [String, Object],
		disableHelp: Boolean,
		disableLang: Boolean,
    app: {
      type: Boolean,
      default: true
    },
		disableMenu: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: "white"
		},
		dark: Boolean
	},
	data: () => ({
		menu: false
	}),
	computed: {
		...mapGetters("global", ["isIOS"]),
    propApp () {
      return this.isIOS ? false : this.app
    }
	}
};
</script>
