<template>
	<v-card
		width="100%"
		:elevation="elevationCard"
		class="rounded-t-lg"
    :class="clickEvent ? '' : 'click-none'"
		@click="$emit('click') "
	>
		<div v-if="!hiddeTransport">
			<v-card
				:color="colorHeader"
				elevation="0"
				width="100%"
				v-if="!dense"
			>
				<v-container class="d-flex flex-column align-center">
					<div class="primary--text subtitle-1 font-weight-medium">
						{{ t("transferType") }}
					</div>
					<div class="font-weight-medium mt-1">
						{{ type }}
					</div>
					<div class="btn-help mr-2 mt-1" v-if="!confirmMode">
						<BtnHelp color="primary40" small />
					</div>
				</v-container>
			</v-card>
			<v-container v-else class="mb-n5">
				<span class="primary--text subtitle-1 font-weight-medium">
					{{ t("transferType") }}:
				</span>
				<span class="font-weight-medium ">
					{{ type }}
				</span>
			</v-container>
		</div>
		<v-container class="my-1 d-flex justify-space-between">
			<div :class="classPassenger">
				<div class="body-2 primary--text ">
					<v-icon
						class="material-icons-outlined mb-1"
						:color="colorIcon"
						>people</v-icon
					>
					{{ t("adults") }}
				</div>
				<div :class="textPassenger">
					{{ countAdults }}
				</div>
			</div>
			<div :class="classPassenger">
				<div class="body-2 primary--text">
					<v-icon
						class="material-icons-outlined mb-1"
						:color="colorIcon"
						>people</v-icon
					>
					{{ t("children") }}
				</div>
				<div :class="textPassenger">
					{{ countChildren }}
				</div>
			</div>
			<div :class="classPassenger">
				<div class="body-2 primary--text">
					<v-icon
						class="material-icons-outlined mb-1"
						:color="colorIcon"
						>people</v-icon
					>
					{{ t("infants") }}
				</div>
				<div :class="textPassenger">
					{{ countInfants }}
				</div>
			</div>
		</v-container>
	</v-card>
</template>

<script>
import BtnHelp from "@/components/btn/BtnHelp";
import { mapState } from "vuex";
export default {
	components: {
		BtnHelp
	},
  data: () => ({
    clickEvent: false
  }),
	props: {
		confirmMode: {
			type: Boolean,
			default: false
		},
		dense: Boolean,
		serviceType: String,
		adults: Number,
		children: Number,
		infants: Number,
		hiddeTransport: Boolean,
		elevation: {
			type: String,
			default: "4"
		}
	},
	computed: {
		...mapState("service", ["checkinModel"]),
		colorHeader: vm => (vm.confirmMode ? "white" : "primary40"),
		colorIcon: vm => (vm.confirmMode ? "secondary" : "primary"),
		elevationCard: vm => (vm.confirmMode ? vm.elevation : "0"),
		classPassenger: vm =>
			vm.dense
				? "d-flex flex-row align-center"
				: "d-flex flex-column align-center",
		textPassenger: vm =>
			vm.dense
				? "ml-1 mb-1 body-1 font-weight-bold"
				: "text-h5 font-weight-bold",

		type: vm => vm.serviceType || vm.checkinModel.serviceType.name || "",
		countAdults: vm => vm.adults || vm.checkinModel.reservation.adults || 0,
		countChildren: vm =>
			vm.children || vm.checkinModel.reservation.children || 0,
		countInfants: vm =>
			vm.infants || vm.checkinModel.reservation.infants || 0
	},
  mounted() {
    if(this.$listeners.click) {
      this.clickEvent = true
    }
  },
	methods: {
		t(key, options = {}) {
			return this.$t(`section.passenger.${key}`, options);
		}
	}
};
</script>
<style lang="scss" scoped>
.btn-help {
	position: absolute;
	right: 0;
}
</style>
