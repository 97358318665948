import PickupForm from "@/views/checkin/form/FormPickupDropOff/FormPickupDropOff";
import CheckinIndex from "@/views/checkin/CheckinIndex";
import CheckinStart from "@/views/checkin/CheckinStart";
export default [
	{
		path: "/checkin/:serviceId",
		component: CheckinIndex,
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: "",
				name: "Start",
				component: CheckinStart
			},
			{
				path: "form",
				component: () => import("@/views/checkin/form/FormIndex"),
				children: [
					{
						path: "contact",
						name: "Contact",
						component: () =>
							import("@/views/checkin/form/FormContact"),
						meta: {
							back: "/confirmation",
							title: "section.contact.header"
						}
					},
					{
						path: "schedule",
						name: "Pickup Schedule",
						component: () =>
							import("@/views/checkin/form/FormPickupSchedule"),
						meta: {
							back: "/reservation/pschedule",
							title: "section.schedule.title"
						}
					},
					{
						path: "flight",
						name: "Flight",
						component: () =>
							import(
								"@/views/checkin/form/FormFlight/FormFlight"
							),
						meta: {
							back: "/reservation/flight",
							title: "section.flight.titleForm"
						}
					},
					{
						path: "location",
						name: "Pickup & Drop Off",
						component: PickupForm,
						meta: {
							back: "/reservation",
							title: "section.pickupDropOff.title"
						}
					},
					{
						path: "location/:id",
						name: "Search Location",
						component: () =>
							import(
								"@/views/checkin/form/FormPickupDropOff/SearchPickupDropOff"
							),
						meta: {
							back: "/form/location",
							title: "section.pickupDropOff.title"
						}
					}
				]
			},
			{
				path: "reservation",
				component: () => import("@/views/checkin/reservation/ReservationIndex"),
				children: [
					{
						path: "",
						name: "Pick up & Drop Off",
						component: () =>
							import(
								"@/views/checkin/reservation/InfoPickupDropOff"
							)
					},
					{
						path: "flight",
						name: "Flight Info",
						component: () =>
							import("@/views/checkin/reservation/InfoFlight")
					},
					{
						path: "pschedule",
						name: "Pickup shedule",
						component: () =>
							import(
								"@/views/checkin/reservation/InfoPickupSchedule"
							)
					},
					{
						path: "passenger",
						name: "Passenger",
						component: () =>
							import("@/views/checkin/reservation/InfoPassenger")
					}
				]
			},
			{
				path: "confirmation",
				name: "Confirmation",
				component: () => import("@/views/checkin/CheckinConfirmation")
			},
			{
				path: "end",
				name: "End",
				component: () => import("@/views/checkin/CheckinEnd")
			},
			{
				path: "closed",
				name: "Closed",
				component: () => import("@/views/checkin/CheckinClosed")
			}
		]
	}
];
