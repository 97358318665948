import {
	SERVICE,
	SET_MODEL,
	SET_SOURCE,
	SET_AUXMODEL,
	SET_REFERENCE,
	SET_RECOMMENDED,
	SET_UPDATE_AUXMODEL
} from "./constants";
import router from "@/router";
import { api } from "@/service/api.service";

export default {
	_setIdService: async ({ commit, dispatch }, { serviceId }) => {
		commit(SERVICE, serviceId);
		localStorage.setItem(SERVICE, serviceId);
		await dispatch("_fetchServiceById");
	},
	_fetchServiceById: async ({ commit, dispatch, state }) => {
		if (!state.serviceId) return;
		const response = await api.get(
			"/travelers/transport-services/" + state.serviceId,
			{
				skipLoading: false
			}
		);
		if (response) {
			const lang = response.data?.reservation?.client?.lang;
			if (lang) {
        dispatch("lang/_changeLang", lang, { root: true });
      }
      // Se identifica el cliente y la reserva en Hotjar
      try {
        const userId = response.data.reservation.client.id
        const reservationId = response.data.reservation.id
        window.hj('identify', userId, {
          reservationId
        })
      } catch (error) {
        console.log('hj identify failed ', error.message)
      }
      //
      const model  = {
        ...response.data,
        pickup: response.data.pickup.id === 10582 ? undefined : response.data.pickup,
        dropoff: response.data.dropoff.id === 10582 ? undefined : response.data.dropoff,
      }
			commit(SET_MODEL, model);
			commit(SET_AUXMODEL, model);
		}
	},
	_updateService: async ({ state }, data) =>
		await api.patch("/travelers/transport-service/" + state.serviceId, {
			data
		}),

	_calcPickupDateTime: async (
		{ state, getters, commit },
		disabledUpdateTime
	) => {
		if (getters.inputDisable) return;
		if (state.dateReference) return;
		if (!getters.existAirport) {
			commit(SET_REFERENCE, null);
			return;
		}

		const { arrival, departure } =
			state.auxModel?.flightValidationData || {};
		const arrivalCountryCode = arrival?.countryCode || null;
		const departureCountryCode = departure?.countryCode || null;

		let flightType = null;
		if (arrivalCountryCode && departureCountryCode) {
			flightType =
				arrivalCountryCode != departureCountryCode
					? "international"
					: "national";
		}

		const params = {
			flightDatetime: state.auxModel.flightDatetime,
			type: getters.getDirection,
			flightType: flightType,
			travelTime: state.checkinModel.travelTime
		};

		return await api
			.post("/travelers/helpers/calculate-pickuptime", {
				data: params
			})
			.then(({ data }) => {
				if (!disabledUpdateTime && !getters.isServiceShare) {
					commit(SET_UPDATE_AUXMODEL, {
						field: "pickupDatetime",
						data: data.toString()
					});
					commit(SET_RECOMMENDED, true);
				}

				commit(SET_REFERENCE, data.toString());
			});
	},
	_updateClient: async ({ state }, data) =>
		await api.patch(
			"/travelers/clients/" + state.checkinModel.reservation.client.id,
			{
				data
			}
		),
	_openCheckin: async ({ commit, dispatch, getters, state }, serviceId) => {
		await dispatch("_setIdService", { serviceId });
		commit(SET_SOURCE, "travelers");
		if (state.checkinModel.checkinStatus == "pending") {
			router.push(getters.getBasePath("", "&utm_source=travelers"));
		} else {
			router.push(
				getters.getBasePath("/reservation", "&utm_source=travelers")
			);
		}
	}
};
