import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import store from "./store";
import router from "./router";
import i18n from "./i18n";
import "dayjs/locale/es";
import api from "./service/api-loader.service";
import './sass/global.scss'

Vue.config.productionTip = false;

Vue.prototype.$setBackRoute = route => store.commit("setRoute", route);
Vue.prototype.$API = api;

store.dispatch("lang/_loadClientLang");

i18n.locale = store.state.lang.lang;
i18n.fallbackLocale = store.state.lang.lang;

export default new Vue({
	vuetify,
	store,
	router,
	i18n,
	data: () => ({
		notification: {
			icon: null,
			closeAutomatic: true,
			show: false,
			type: "info",
			title: null,
			description: null,
      contact: false,
			btn: null
		},
		loading: false
	}),
	mounted(){
		console.log('main running ')
	},
	methods: {
		show(options) {
			this.notification = {
				...this.notification,
				...options
			};
			this.notification.show = true;
		}
	},
	render: h => h(App)
}).$mount("#app");
