<template>
	<v-card
		:elevation="elevation"
		class="rounded-lg d-flex flex-row"
		width="100%"
		:color="color"
		@click="$emit('click')"
	>
		<slot name="icon">
			<v-icon class="mx-2 material-icons-outlined" :color="iconColor">{{
				icon
			}}</v-icon>
		</slot>
		<v-card-text class="ml-n3 my-n3">
			<div class="primary--text body-2">
				{{ title }}
			</div>
			<div class="body-2 font-weight-medium">
				<slot />
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		title: String,
		icon: String,
		elevation: {
			type: String,
			default: "4"
		},
		color: String,
		iconColor: {
			type: String,
			default: "secondary"
		}
	}
};
</script>

<style></style>
