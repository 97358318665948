import axios from "@/plugins/axios";
import store from "@/store";
import vm from "@/main";

const showLoader = () => store.commit("global/SET_LOADING", true);
const hideLoader = () => store.commit("global/SET_LOADING", false);
const successHandler = response => ({
	success: true,
	data: response.data,
	response: response
});
const errorHandler = (response, show) => {
	if (show)
		vm.show({
			type: "error",
			title: vm.$i18n.t("global.message.error.title"),
			description:
				response?.data?.error === "aftertime"
					? vm.$i18n.t("info.locked.aftertime")
					: vm.$i18n.t("global.message.error.message")
		});
	return {
		success: false,
		data: response?.data || null,
		response: response
	};
};
export const api = {
	get: async (url, { params = "", skipLoading = true }) => {
		const request = {
			method: "GET",
			url,
			params
		};
		if (!skipLoading) showLoader();
		try {
			const response = await axios(request);
			if (!skipLoading) hideLoader();
			return response;
		} catch (e) {
			if (!skipLoading) hideLoader();
			console.log(e);
		}
	},
	post: async (url, { data, skipLoading = false, showError = true }) => {
		const request = {
			method: "POST",
			url: url,
			data: data
		};
		if (!skipLoading) showLoader();
		try {
			const response = await axios(request);
			return successHandler(response);
		} catch (e) {
			throw errorHandler(e.response, showError);
		} finally {
			if (!skipLoading) hideLoader();
		}
	},
	patch: async (url, { data, skipLoading = false, showError = true }) => {
		const request = {
			method: "PATCH",
			url: url,
			data: data
		};
		if (!skipLoading) showLoader();
		try {
			const response = await axios(request);
			return successHandler(response);
		} catch (e) {
			throw errorHandler(e.response, showError);
		} finally {
			if (!skipLoading) hideLoader();
		}
	},
	delete: async url => {
		const request = {
			method: "DELETE",
			url: url
		};
		try {
			const response = await axios(request);
			return response;
		} catch (e) {
			console.log(e);
		}
	}
};
