<template>
	<div>
		<v-btn
			@click="dialog = true"
			:color="dark ? 'white' : outlined ? 'primary' : color"
			elevation="0"
			fab
			:dark="dark"
			:small="small"
			:outlined="outlined"
		>
			<v-icon
				class="material-icons-outlined"
				:color="dark ? 'white' : 'primary'"
				>support_agent</v-icon
			>
		</v-btn>
		<DialogHelp v-model="dialog" />
	</div>
</template>

<script>
import DialogHelp from "@/components/dialog/DialogHelp";
export default {
	components: {
		DialogHelp
	},
	props: {
		outlined: Boolean,
		color: {
			type: String,
			default: "white"
		},
		small: Boolean,
		dark: Boolean
	},
	data: () => ({
		dialog: false
	})
};
</script>
