<template>
  <div>
    <v-carousel
      v-if="items.length > 0"
      v-model="model"
      height="30vh"
      hide-delimiters
      show-arrows
      class="d-flex justify-center"
      hide-delimiter-background
    >
      <v-container class="py-0 my-0">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.url"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <div
            class="counter-gallery caption d-flex justify-center align-center white--text"
          >
            {{ model + 1 }}/{{ items.length }}
          </div>
        </v-carousel-item>
      </v-container>
    </v-carousel>
    <div v-else style="height: 30vh"></div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    model: 0
  })
};
</script>
<style lang="scss" scoped>
.counter-gallery {
  position: absolute;
  width: 40px;
  height: 18px;
  right: 10px;
  bottom: 10px;

  background: rgba(2, 29, 62, 0.5);
  border-radius: 2px;
}
</style>
