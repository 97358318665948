import { render, staticRenderFns } from "./PaymentProducts.vue?vue&type=template&id=d78638e4&scoped=true&"
import script from "./PaymentProducts.vue?vue&type=script&lang=js&"
export * from "./PaymentProducts.vue?vue&type=script&lang=js&"
import style0 from "./PaymentProducts.vue?vue&type=style&index=0&id=d78638e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d78638e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VDivider,VFooter,VForm,VRow})
