export function mapFields({
	fields,
	base,
	module,
	mutation,
	fieldArray,
	index = undefined
}) {
	const object = {};
	for (let x = 0; x < fields.length; x++) {
		const field = [fields[x]];
		object[field] = {
			get() {
				if (index === undefined)
					return this.$store.state[module][base][field];
				return this.$store.state[module][base][fieldArray][index][
					field
				];
			},
			set(value) {
				const payload = {
					index: index,
					field: fields[x],
					data: value
				};
				this.$store.commit(module + "/" + mutation, payload);
			}
		};
	}
	return object;
}
