export default {
	isArrival: state => (state.checkinModel.pickup?.isAirport ? true : false),
	getDirection: state =>
		state.checkinModel.pickup?.isAirport ? "arrival" : "departure",
	existAirport: state =>
		state.checkinModel.pickup?.isAirport ||
		state.checkinModel.dropoff?.isAirport,
	getUtmSource: state =>
		state.utm_source ? "&utm_source=" + state.utm_source : "",
	getBasePath: (state, getters, rootState) => route =>
		`/checkin/${state.serviceId}${route}?token=${rootState.auth.editToken}${getters.getUtmSource}`,
	getSalesChannel: state => state.checkinModel?.salesChannel?.name || "",
	inputDisable: state => state.checkinModel.locked !== null,
	inputDisableType: state => state.checkinModel.locked,
	isServiceShare: state =>
		state.checkinModel.serviceType.serviceType === "shared",
	closedMode: state => state.checkinModel.locked == "client",
  isIataCun: state => state.checkinModel.dropoff?.iata === 'CUN' || state.checkinModel.pickup?.iata === 'CUN'
};
