import { TOKEN, EDIT_TOKEN } from "./constants";
import { api } from "@/service/api.service";
import router from "@/router";
export default {
	_login: async (
		{ commit, dispatch, state, rootState },
		{ token = undefined, path = undefined, serviceId = undefined }
	) => {
		const serviceIdFromStorage = rootState.service.serviceId;
		try {
			/*
        Intentamos loguearnos
        1 - Si no tenemos ningun token en la memoria
        2 - Si el serviceId es diferente al que tenemos en memoria \
      */
			if (!state.token || serviceId != serviceIdFromStorage) {
				const response = await api.get("/travelers/reservation/login", {
					params: { token },
					skipLoading: false
				});
				commit(TOKEN, response.data.access_token);
				commit(EDIT_TOKEN, token);
			}
			/*       if (path === "/") {
        dispatch("global/_initIntercom", null, { root: true });
        dispatch("global/_hideIntercomLauncher", null, { root: true });
      } */
			if (path.includes("/tracking")) {
				return await dispatch(
					"service/_setIdService",
					{ serviceId },
					{ root: true }
				);
			}
			if (path.includes("/checkin")) {
				await dispatch(
					"service/_setIdService",
					{ serviceId },
					{ root: true }
				);
				dispatch("global/_initIntercom", null, { root: true });
				dispatch("global/_hideIntercomLauncher", null, { root: true });
			}
		} catch (error) {
			console.log(error);
			dispatch("_logout");
			return router.replace({ path: "/login" });
		}
	},
	_loginApp: async ({ commit }, { client, reference }) => {
		const response = await api.post("/travelers/login", {
			data: { reference, client },
			showError: false
		});
		commit(TOKEN, response.data.access_token);
		router.push("/");
		/*     dispatch("global/_initIntercom", null, { root: true });
    dispatch("global/_hideIntercomLauncher", null, { root: true }); */
	},
	_logout: async ({ commit }) => {
		commit(TOKEN, null);
		commit(EDIT_TOKEN, null);
		router.replace("/login");
	}
};
