<script>
import { mapState } from "vuex";
import { mapFields } from "@/helpers/mapFields";
import { calculateDistanceAndTime } from "@/helpers/utils";
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    directionsService: null,
    directionsRenderer: null,
  }),
  computed: {
    ...mapState("service", ["auxModel"]),
    ...mapFields({
      fields: ["travelLength", "travelTime"],
      base: "auxModel",
      mutation: "SET_UPDATE_AUXMODEL",
      module: "service",
    }),
  },
  methods: {
    init() {
      this.directionsService = new this.google.maps.DirectionsService();
      this.directionsRenderer = new this.google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);
      if (this.isSelectedPickupAndDropoff()) this.updateDirections();
    },
    isSelectedPickupAndDropoff() {
      if (
        this.auxModel.pickup?.latitude &&
        this.auxModel.pickup?.longitude &&
        this.auxModel.dropoff?.latitude &&
        this.auxModel.dropoff?.longitude
      )
        return true;
      return false;
    },
    async updateDirections() {
      try {
        const { time, length, data } = await calculateDistanceAndTime(
          this.auxModel
        );
        this.directionsRenderer.setDirections(data);
        this.travelTime = time;
        this.travelLength = length;
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    this.init();
  },
  // eslint-disable-next-line
  render() {},
};
</script>
