<template>
	<v-card
		class="rounded-lg"
		:color="colorCard"
		:elevation="elevationCard"
		width="100%"
	>
		<v-container class="d-flex flex-row">
			<v-container class="my-n2">
				<div :class="`primary--text body-2 ${classTitle}`">
					Shuttle Central <br />
					{{ t("bookingNumber") }}
				</div>
				<div class="mt-2 font-weight-bold body-2">{{ shuttleId }}</div>
			</v-container>
			<v-divider class="mx-1" vertical></v-divider>
			<v-container class="my-n2">
				<div :class="`primary--text body-2 ${classTitle}`">
					{{ channelName }} <br />
					{{ t("bookingNumber") }}
				</div>
				<div class="mt-2 font-weight-bold body-2">{{ channelRef }}</div>
			</v-container>
		</v-container>
	</v-card>
</template>

<script>
export default {
	props: {
		confirmMode: {
			type: Boolean,
			default: false
		},
		service: Object
	},
	computed: {
		colorCard: vm => (vm.confirmMode ? "white" : "primary40"),
		elevationCard: vm => (vm.confirmMode ? "4" : "0"),
		classTitle: vm =>
			vm.confirmMode ? "font-weight-thin" : "font-weight-medium",
		shuttleId: vm =>
			vm.service?.reservation?.id || vm.service.serviceNumber || "",
		channelRef: vm => vm.service?.salesChannelReference || "",
		channelName: vm => vm.service?.salesChannel?.name || ""
	},
	methods: {
		t(key, options = {}) {
			return this.$t(`section.pickupDropOff.${key}`, options);
		}
	}
};
</script>

<style></style>
