<template>
	<div>
		<v-container>
			<v-container>
				<span class="title font-weight-bold">{{ t("title") }}</span>
				<v-divider class="mt-2"></v-divider>
				<div
					class="d-flex flex-row justify-space-between mt-4"
					v-for="(detail, i) of details"
					:key="i"
				>
					<span class="title font-weight-normal subtitle-2">{{
						detail.text
					}}</span>
					<span class="title font-weight-bold subtitle-2"
						>{{ marketModel.bookingTotal.currency }} ${{
							detail.value
						}}</span
					>
				</div>
				<v-divider class="mt-2"></v-divider>
			</v-container>

			<v-container>
				<v-form ref="form">
					<v-row dense>
						<v-col class="py-0" cols="12">
							<TextField
								:title="t('holder')"
								class="atlas-mask data-hj-suppress"
								v-model="model.cardHolder"
							/>
						</v-col>
						<v-col class="py-0" cols="12">
							<TextField

								:title="t('number')"
								class="atlas-mask data-hj-suppress"
								v-model="cardNumber"
							>
								<template
									v-slot:append
									v-if="
										cardConfig &&
											[
												'visa',
												'mastercard',
												'american-express',
												'discover'
											].includes(cardConfig.type)
									"
									><div
										:class="
											`credit-card-type ${cardConfig.type}`
										"
									></div
								></template>
							</TextField>
						</v-col>
						<v-col class="py-0" cols="6">
							<TextField
								:rules="rules.cardDate"
								type="number"
								class="atlas-mask data-hj-suppress"
								v-mask="'##/####'"
								:title="t('date')"
								v-model="model.cardDate"
							/>
						</v-col>
						<v-col class="py-0" cols="6">
							<TextField class="atlas-mask data-hj-suppress" :title="t('cvv')" v-model="model.cvv" />
						</v-col>
					</v-row>
				</v-form>
			</v-container>
		</v-container>
		<v-footer class="max-width-viewport-container" color="white" elevation="3" app>
			<v-container class="d-flex flex-row justify-space-between">
				<v-btn
					elevation="0"
					block
					class="text-none font-weight-bold"
					color="primary"
					rounded
					@click="checkout"
				>
					<span class="mx-3">{{ t("btnPay") }}</span>
				</v-btn>
			</v-container>
		</v-footer>
	</div>
</template>

<script>
import TextField from "@/components/field/TextField";
import { mapFields } from "vuex-map-fields";
import creditCardType from "credit-card-type";
import { mask } from "vue-the-mask";
import { mapState, mapActions } from "vuex";
import { getTexts } from "@/helpers/market";
export default {
	directives: {
		mask
	},
	components: {
		TextField
	},
	data: vm => {
		const modelDefault = () => ({
			cardHolder: "",
			cardNumber: "",
			cardDate: "",
			cvv: ""
		});
		return {
			cardConfig: null,
			model: modelDefault(),
			modelDefault: modelDefault(),
			rules: {
				cardDate: [
					v => !!v || vm.$t("global.required"),
					v =>
						/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/g.test(v) ||
						vm.t("ruleCardExp")
				]
			}
		};
	},
	activated() {
		this.$setBackRoute({
			name: "Product detail",
			...this.$route.param
		});
		if (!this.marketModel.additionalServiceTypeId) {
			this.$router.push({
				name: "Travelers"
			});
		}
	},

	computed: {
		...mapState("market", {
			marketModel: "model"
		}),
		...mapFields("market", ["model.card"]),
		cardNumber: {
			get() {
				return this.model.cardNumber;
			},
			set(v) {
				this.model.cardNumber = this.cardNumberFormatter(v);
			}
		},
		details() {
			let text = this.marketModel.name;
			let value = 0;
			this.marketModel.rates.map(rate => {
				value += rate.total;
				rate.prices.map(price => {
					if (price.quantity > 0) {
						text = `${text} ${getTexts(price.category).title.charAt(
							0
						)}x${price.quantity}`;
					}
				});
			});
			return [{ text, value: Intl.NumberFormat("en-US").format(value) }];
		}
	},
	mounted() {
		window.Stripe.setPublishableKey(process.env.VUE_APP_STRIPE);
	},

	methods: {
		...mapActions("market", ["_resetModel"]),
		cardNumberFormatter(value) {
			const cardInfo = creditCardType(value);
			value = value.replace(/\D/g, "");
			if (cardInfo.length === 1) {
				this.cardConfig = cardInfo[0];
				return this.prettyCardNumber(
					value.replace(/\s+/g, ""),
					cardInfo[0]
				);
			} else {
				this.cardConfig = null;
			}
			return value;
		},
		prettyCardNumber(cardNumber, cardTypeConfig) {
			const offsets = [].concat(
				0,
				cardTypeConfig.gaps,
				cardNumber.length
			);
			const components = [];

			for (let i = 0; offsets[i] < cardNumber.length; i++) {
				const start = offsets[i];
				const end = Math.min(offsets[i + 1], cardNumber.length);
				components.push(cardNumber.substring(start, end));
			}
			return components.join(" ");
		},
		checkout() {
			if (!this.$refs.form.validate()) return;
			this.$root.loading = true;
			window.Stripe.card.createToken(
				{
					name: this.model.cardHolder,
					number: this.model.cardNumber,
					cvc: this.model.cvv,
					exp_month: this.model.cardDate.split("/")[0],
					exp_year: this.model.cardDate.split("/")[1]
				},
				(status, response) => {
					if (response.error) {
						this.$root.loading = false;
						this.$root.show({
							type: "error",
							title: this.$t("global.message.error.title"),
							description: response.error.message
						});
					} else {
						this.card = response.id;
						this.doCheckout();
					}
				}
			);
		},
		doCheckout() {
			const rates = [];
			this.marketModel.rates.filter(rate => {
				//Se filtra los "prices" que no fueron seleccionados (cantidad 0)
				const prices = rate.prices.filter(
					({ quantity }) => quantity > 0
				);
				//Se filtra los "rates" que no fueron seleccionados
				if (prices.length > 0) rates.push({ ...rate, prices });
			});
			const data = {
				...this.marketModel,
				rates
			};

			this.$API.additionalServices
				.checkout(data)
				.then(() => {
					if (window.location.host === "ride.shuttlecentral.com") {
						// Esto se usa para hacer tracking en Google Analytics
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							event: "conversion",
							conversion: this.marketModel.name
						});
						//
					}
					this._resetModel();
					this.model = JSON.parse(JSON.stringify(this.modelDefault));
					this.$refs.form.resetValidation();
					this.$router.push({
						name: "Success",
						...this.$route.param
					});
				})
				.catch(() => {
					this.$root.show({
						type: "error",
						title: this.$t("global.message.error.title"),
						description: this.$t("global.message.error.message")
					});
				})
				.then(() => {
					this.$root.loading = false;
				});
		},

		t(key, options = {}) {
			return this.$t(`market.product.payment.${key}`, options);
		}
	}
};
</script>
<style lang="scss" scoped>
.credit-card-type {
	margin-top: -7px;
	margin-right: -7px;
	width: 53px;
	height: 38px;
	background: url("~@/assets/credit-card.png");
	background-size: 296px;
}
.credit-card-type.mastercard {
	background-position: 53px;
}
.credit-card-type.american-express {
	background-position: 175px;
}
.credit-card-type.discover {
	background-position: 114px;
}
</style>
