import TravelersPortal  from "@/views/traveler/TravelerPortal";

export default [
	{
		path: "/",
		name: "Travelers",
		component: TravelersPortal,
		meta: {
			requiresAuth: true
		}
	}
];
