import { TOKEN, EDIT_TOKEN } from "./constants";
export default {
	[TOKEN]: (state, payload) => {
		state.token = payload;
		localStorage.setItem(TOKEN, payload);
	},
	[EDIT_TOKEN]: (state, payload) => {
		state.editToken = payload;
		localStorage.setItem(EDIT_TOKEN, payload);
	}
};
