import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const files = require.context("", false, /^\.\/.*\.router.js$/);
const routes = files
	.keys()
	.reduce((acc, curr) => acc.concat(files(curr).default), []);

const router = new VueRouter({
	mode: "history",
	routes
});

router.beforeEach(async (to, from, next) => {
	const isClosedMode = store.getters["service/closedMode"];
	const isAuthenticated = store.getters["auth/isAuth"];
	if (to.path === "/login" && isAuthenticated) return next("/");
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (isAuthenticated) {
			// Si el proceso de checkin ya esta finalizado redirigimos a la vista de closed
			if (isClosedMode) {
				if (
					to.path.includes("/checkin") &&
					to.name != "Start" &&
					to.name != "Confirmation" &&
					to.name != "Closed" &&
					!to.path.includes("/contact") &&
					!to.path.includes("/end")
				) {
					return next({
						path: `/checkin/${to.params.serviceId}/closed`,
						query: to.query
					});
				}
			}
			if (!to.query.token) {
				if (store.state.auth.editToken) {
					to.query.token = store.state.auth.editToken;
					return router.push({ path: to.path, query: to.query });
				}
			}
			return next();
		}
		const { serviceId } = to.params;
		const { token } = to.query;
		const { path } = to;
		await store.dispatch("auth/_login", { serviceId, token, path });
	}

	next();
});

export default router;
