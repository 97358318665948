<template>
	<v-dialog max-width="500px" :value="value" @input="close">
		<v-card>
			<BtnClose @click="close" />
			<v-container class="d-flex flex-column align-center">
				<v-card-title class="my-n2">
					<v-icon color="primary" size="60">error_outline</v-icon>
				</v-card-title>
				<v-card-subtitle class="font-weight-black mt-1 mb-n1">
					{{ t("title") }}
				</v-card-subtitle>

				<v-card-text class="font-weight-regular">
					<div>
						{{ t("firstText") }}
					</div>
					<div class="mt-4">
						<span class="font-weight-black">
							{{ t("warning") }}</span
						>
						{{ t("secondText") }}
					</div>
				</v-card-text>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
export default {
	components: {
		BtnClose
	},
	props: {
		value: Boolean
	},
	methods: {
		close() {
			this.$emit("input", false);
		},
		t(key, options = {}) {
			return this.$t(`info.arrival.${key}`, options);
		}
	}
};
</script>
