<template>
	<v-dialog max-width="500px" :value="value" @input="close">
		<v-card>
			<BtnClose @click="close" />
			<v-container class="d-flex flex-column align-center">
				<v-card-title class="mb-n2 cancun-info">
					<v-icon color="primary" size="60">error_outline</v-icon>
          <BtnLanguage />
				</v-card-title>
					<img v-if="lang === 'es'" loading="lazy" class="img-info" src="~@/assets/aux/cun_es.jpg" alt="">
          <img v-else loading="lazy" class="img-info" src="~@/assets/aux/cun_en.jpg" alt="">
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
import BtnLanguage from "@/components/btn/BtnLanguage";
import { mapState } from 'vuex';
export default {
	components: {
    BtnLanguage,
		BtnClose
	},
	props: {
		value: Boolean
	},
  computed: {
    ...mapState('lang', ['lang'])
  },
	methods: {
		close() {
			this.$emit("input", false);
		}
	}
};
</script>
<style lang="scss" scoped>
.img-info {
  object-fit: contain;
  width: 100%;
  height: auto;
}
::v-deep .cancun-info {
  button {
    position: absolute;
    right: 1rem;
  }
}
</style>
