export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_MODEL = "SET_MODEL";
export const initialState = () => ({
	name: null,
	additionalServiceTypeId: null,
	reservationId: null,
	clientNotes: null,
	iata: null,
	rates: [],
	card: null,
	bookingTotal: {
		total: null,
		currency: null
	}
});
