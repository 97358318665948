<template>
	<div>
		<ProductGallery v-if="product" :items="product.content.gallery" />

		<v-container v-if="product">
			<v-container
				class="d-flex flex-row justify-space-between align-center"
			>
				<div class="d-flex flex-row align-center">
					<v-img
						max-height="auto"
						max-width="45px"
						:src="iconProduct"
					></v-img>
					<span class="ml-2 subtitle-1 font-weight-bold">{{
						product.content.name
					}}</span>
				</div>
				<v-select
					class="select-width"
					background-color="success"
					hide-details
					solo
					dense
					item-value="value"
					item-text="value"
					:loading="loading"
					v-model="currency"
					:items="currencyOptions"
					@input="recalculate()"
				>
					<template v-slot:selection="data">
						<v-list-item class="pa-0 mx-0 my-n2">
							<v-list-item-content>
								<v-list-item-title
									class="d-flex justify-space-between  subtitle-2"
								>
									<span class="mr-1 ml-2"
										>{{ data.item.value }}$</span
									>
									<div
										class="d-flex flex-column align-end mb-n1"
									>
										<div>{{ mainPrice }}</div>
										<div class="caption">
											{{ mainCategory }}
										</div>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<template v-slot:item="data">
						<v-list class="pa-0 ma-0">
							<v-list-item class="pa-0 ma-0">
								<v-list-item-icon>
									<v-img
										max-width="24px"
										max-heigth="24px"
										:src="data.item.icon"
									>
									</v-img>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>
										{{ data.item.value }}
									</v-list-item-title>
								</v-list-item-content>
								<v-list-item-icon>
									<v-icon
										color="success"
										class="material-icons-outlined"
										v-if="currency === data.item.value"
										>check_circle</v-icon
									>
								</v-list-item-icon>
							</v-list-item>
						</v-list>
					</template>
				</v-select>
			</v-container>
			<v-divider class="mt-3"></v-divider>
			<v-container>
				<div
					v-html="product.content.description"
					class="content-description"
				></div>
			</v-container>
		</v-container>

		<div v-if="product && ratesModel.length > 0">
			<v-footer
				class="max-width-viewport-container"
				color="white"
				elevation="3"
				app
				v-if="product.relatedTo == 'Reservations'"
			>
				<v-container class="d-flex flex-row justify-space-between">
					<BtnCounter
						v-if="ratesModel[0].prices.length === 1"
						:minimun="1"
						v-model="ratesModel[0].prices[0].quantity"
						@input="calculateTotals"
					/>
					<v-btn
						v-else
						class="text-none"
						block
						color="primary"
						@click="sheet = !sheet"
						rounded
						outlined
						>{{ t("btnSelectQuantity") }}</v-btn
					>
					<v-btn
						elevation="0"
						v-if="ratesModel[0].prices.length === 1"
						class="text-none font-weight-bold"
						color="primary"
						rounded
						:disabled="total === 0"
						:loading="loading"
						@click="toPayment"
					>
						<span class="mx-3" v-if="!loading"
							>{{ t("btnPay") }} ${{ total }}</span
						>
					</v-btn>
				</v-container>
			</v-footer>
			<v-footer
				class="max-width-viewport-container"
				style="z-index: 999;"
				color="white"
				elevation="3"
				app
				v-else
			>
				<v-container class="d-flex flex-row justify-space-between">
					<v-btn
						class="text-none"
						block
						color="primary"
						@click="sheet = !sheet"
						rounded
						outlined
						>{{ t("btnSelectQuantity") }}</v-btn
					>
				</v-container>
			</v-footer>
			<v-bottom-sheet
				max-width="700px"
				class="mb-10 "
				content-class="overflow-auto"
				hide-overlay
				v-model="sheet"
			>
				<v-sheet class="rounded-t-lg">
					<v-card-text
						class="text-center"
						v-if="product.relatedTo == 'TransportServices'"
					>
						{{ t("description") }}
						<strong>{{ product.content.name }}</strong>
					</v-card-text>
					<v-sheet v-for="(rate, i) of ratesModel" :key="i">
						<v-sheet
							color="primary20"
							v-if="
								getTransportServiceById(rate.transportServiceId)
							"
						>
							<v-card-text
								class="text-center font-weight-bold primary--text mb-n5 py-2"
							>
								{{
									t(
										`labels.${
											getTransportServiceById(
												rate.transportServiceId
											).trip
										}.service`
									)
								}}

								#{{ rate.serviceNumber }}
							</v-card-text>
						</v-sheet>
						<v-container>
							<div
								class="d-flex flex-row justify-space-between align-center mx-4 mt-4"
								v-for="(price, j) of rate.prices"
								:key="`${i}${j}`"
							>
								<div
									:style="rate.disabled ? 'opacity: 0.3' : ''"
								>
									<div
										class="primary--text font-weight-bold subtitle-2"
									>
										{{ getTexts(price.category).title }} ${{
											price.price
										}}
									</div>
									<div class="subtitle-2 bluegrey--text">
										{{ getTexts(price.category).subtitle }}
									</div>
								</div>
								<span>
									<BtnCounter
										:disabled="rate.disabled"
										v-model="
											ratesModel[i].prices[j].quantity
										"
										@input="calculateTotals"
									/>
								</span>
							</div>
						</v-container>
						<v-sheet color="primary" v-if="getDetail(rate)">
							<v-card-text
								class="text-center font-weight-normal white--text  py-0"
							>
								{{
									rate.prices.length > 1
										? t("detail.ticket")
										: t("detail.aditional")
								}}: {{ getDetail(rate) }}
							</v-card-text>
						</v-sheet>
						<v-container v-if="rate.disabled" class="pt-0 pb-2">
							<div
								class="secondary--text mx-4 font-weight-bold subtitle-2"
							>
								{{ t("detail.notAvailable") }}
							</div>
						</v-container>
					</v-sheet>
					<v-footer color="white" elevation="3">
						<v-container
							class="d-flex flex-row justify-space-between"
						>
							<v-btn
								class="text-none"
								color="primary"
								@click="sheet = !sheet"
								rounded
								outlined
								>{{ t("btnCancel") }}</v-btn
							>
							<v-btn
								elevation="0"
								class="text-none font-weight-bold"
								color="primary"
								rounded
								:disabled="total === 0"
								:loading="loading"
								@click="toPayment"
							>
								<span class="mx-3" v-if="!loading"
									>{{ t("btnPay") }} ${{ total }}</span
								>
							</v-btn>
						</v-container>
					</v-footer>
				</v-sheet>
			</v-bottom-sheet>
		</div>
	</div>
</template>

<script>
import BtnCounter from "@/components/btn/BtnCounter";
import ProductGallery from "@/components/market/ProductGallery";
import { currencyOptions } from "@/helpers/constants";
import {
	getPrice,
	getCategoryPrice,
	getIcon,
	getTexts
} from "@/helpers/market";
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
export default {
	components: {
		BtnCounter,
		ProductGallery
	},
	data: () => ({
		sheet: false,
		product: null,
		currencyHasConverted: false,
		loading: false,
		ratesModel: [],
		currencyOptions
	}),
	activated() {
		this.$setBackRoute({
			name: "Travelers"
		});
	},
	watch: {
		ratesModel: {
			handler(v) {
				if (!isEqual(v, this.rates)) {
					this.rates = cloneDeep(v);
				}
			},
			deep: true
		},
		rates(v) {
			this.ratesModel = [];
			this.ratesModel = v;
		},
		lang() {
			this._getProductById({
				id: this.$route.params.id,
				refresh: true
			}).then(product => {
				this.product.content.description = product.content.description;
				this.product.content.name = product.content.name;
				this.convertCurrency();
			});
		}
	},
	mounted() {
		this.$root.loading = true;
		this._getProductById({ id: this.$route.params.id })
			.then(product => {
				if (!product) return this.back();
				this.product = product;
			})
			.catch(e => {
				console.error(e);
				this.back();
			})
			.then(() => (this.$root.loading = false));
	},
	computed: {
		...mapFields("market", [
			"model.rates",
			"model.bookingTotal.total",
			"model.bookingTotal.currency"
		]),
		...mapGetters("reservation", ["getTransportServiceById"]),
		lang: vm => vm.$store.state.lang.lang,
		mainPrice() {
			return getPrice(this.product.prices);
		},
		mainCategory() {
			return this.getTexts(getCategoryPrice(this.product.prices)).title;
		},
		iconProduct() {
			return getIcon(this.product?.code, true);
		}
	},
	methods: {
		...mapActions("market", ["_getProductById"]),
		back() {
			this.$router.push({
				name: "Travelers"
			});
		},
		getDetail(rate) {
			return rate.prices.reduce(
				(acc, curr) =>
					(acc +=
						curr.quantity > 0
							? ` ${this.getTexts(curr.category).title}: ${
									curr.quantity
							  }`
							: ""),
				""
			);
		},
		recalculate() {
			this.currencyHasConverted = false;
			this.convertCurrency();
		},
		calculateTotals() {
			const calculateRateTotal = prices =>
				prices.reduce(
					(acc, curr) => (acc += curr.price * curr.quantity),
					0
				);

			const calculateGlobalTotal = rates =>
				rates.reduce((acc, curr) => (acc += curr.total), 0);

			this.ratesModel = this.ratesModel.map(rate => ({
				...rate,
				total: calculateRateTotal(rate.prices)
			}));

			this.total = calculateGlobalTotal(this.ratesModel);
		},
		convertCurrency() {
			if (this.currency) {
				if (!this.currencyHasConverted) {
					this.currencyHasConverted = true;
					this.loading = true;
					this.$API.additionalServices
						.convertCurrency(this.$route.params.id, {
							currency: this.currency
						})
						.then(r => {
							this.product.prices = cloneDeep(r);
							this.ratesModel = this.ratesModel.map(rate => ({
								...rate,
								prices: rate.prices.map(price => {
									const priceConverted = r.find(
										({ category }) =>
											category === price.category
									).price;
									return { ...price, price: priceConverted };
								})
							}));
							this.calculateTotals();
						})
						.catch(() => {
							this.currencyHasConverted = false;
							console.error("Error convertCurrency");
						})
						.then(() => (this.loading = false));
				}
			}
		},
		toPayment() {
			this.$router.push({
				name: "Payment",
				...this.$route.param
			});
		},
		t(key, options = {}) {
			return this.$t(`market.product.detail.${key}`, options);
		},
		getTexts(category) {
			return getTexts(category);
		}
	}
};
</script>
<style lang="scss" scoped>
.select-width {
	max-width: 120px !important;
}
::v-deep
	.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
	> .v-input__control
	> .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
	padding: 0 2px !important;
}
</style>
<style>
.content-description p {
	margin-bottom: 0px !important;
}
</style>
