<template>
	<v-dialog max-width="500px" :value="value" @input="close">
		<v-card>
			<BtnClose @click="close" />
			<v-container class="d-flex flex-column align-center">
				<v-card-title class="my-n2">
					<v-icon color="primary" size="60">error_outline</v-icon>
				</v-card-title>
				<v-card-subtitle class="font-weight-black mt-1 mb-n1">
					COVID 19
				</v-card-subtitle>
				<v-card-text class="font-weight-regular">
					<table style="width:100%" class="mx-n2">
						<tr
							v-for="(info, i) of t('covid')"
							:key="i"
							class="d-flex align-start mb-3"
						>
							<td class="mr-2 mt-n1">
								<v-icon
									color="green"
									class="material-icons-outlined"
									>check_circle</v-icon
								>
							</td>
							<td class="body-2">
								{{ info }}
							</td>
						</tr>
					</table>
				</v-card-text>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
export default {
	components: {
		BtnClose
	},
	props: {
		value: Boolean
	},
	methods: {
		close() {
			this.$emit("input", false);
		},
		t(key, options = {}) {
			return this.$t(`info.${key}`, options);
		}
	}
};
</script>
