import GoogleMaps from "@/plugins/maps";
import { hosts, routeBase } from "./constants";

export const LIST_HOURS = () => {
	let hours = [];
	let hour;
	for (let i = 0; i < 24; i++) {
		if (i > 9) {
			hour = {
				value: `${i}`,
				text: i + ":00"
			};
		} else {
			hour = {
				value: `${i}`,
				text: "0" + i + ":00"
			};
		}
		hours.push(hour);
	}
	return hours;
};
export const calculateDistanceAndTime = async ({ pickup, dropoff }) => {
	if (!pickup || !dropoff) return;
	const google = await GoogleMaps;
	const direction = new google.maps.DirectionsService();
	let data = { time: null, length: null, data: null };
	await direction.route(
		{
			origin: new google.maps.LatLng(
				Number(pickup.latitude),
				Number(pickup.longitude)
			),
			destination: new google.maps.LatLng(
				Number(dropoff.latitude),
				Number(dropoff.longitude)
			),
			travelMode: google.maps.TravelMode.DRIVING
		},
		(response, status) => {
			if (status === "OK") {
				data = {
					time: response.routes[0].legs[0].duration.value / 60,
					length: response.routes[0].legs[0].distance.value / 1000,
					data: response
				};
			}
		}
	);
	return data;
};

export const getPath = url => {
	let serviceId = undefined;
	let path;
	let token;
	let temp;
	// Obtener path
	hosts.forEach(h => {
		if (url.includes(h)) {
			path = url.split(h)[1];
		}
	});
	// Obtener token
	if (path) {
		temp = path.split("token=")[1];
		if (temp.split("&").length > 0) {
			token = temp.split("&")[0];
		} else {
			token = temp;
		}
	}
	// Obtener serviceId
	if (path) {
		const array = path.split("/");
		array.forEach((x, i) => {
			if (routeBase.includes(x)) {
				temp = array[i + 1];
				if (temp.split("?").length > 0) {
					serviceId = temp.split("?")[0];
				} else {
					serviceId = temp;
				}
			}
		});
	}
	return { path, serviceId, token };
};
