import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		backRoute: null
	},
	mutations: {
		setRoute(state, payload) {
			state.backRoute = payload;
		}
	},
	modules
});
export default store;
