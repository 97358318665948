import {
	SERVICE,
	SET_MODEL,
	SET_SOURCE,
  RESET_STATE,
	SET_AUXMODEL,
	SET_REFERENCE,
	SET_RECOMMENDED,
	SET_UPDATE_MODEL,
	SET_UPDATE_AUXMODEL
} from "./constants";
export default {
	[SERVICE]: (state, payload) => (state.serviceId = payload),
	[SET_MODEL]: (state, payload) => (state.checkinModel = payload),
	[SET_AUXMODEL]: (state, payload) => (state.auxModel = payload),
	[SET_RECOMMENDED]: (state, payload) => (state.recommendedTime = payload),
	[SET_REFERENCE]: (state, payload) => (state.dateReference = payload),
	[SET_SOURCE]: (state, payload) => (state.utm_source = payload),
  [RESET_STATE]: (state) => {
    state.recommendedTime = false;
    state.dateReference = null;
    state.utm_source = null;
  },
	[SET_UPDATE_MODEL]: (state, payload) => {
		state.checkinModel = {
			...state.checkinModel,
			[payload.field]: payload.data
		};
		state.auxModel = {
			...state.auxModel,
			[payload.field]: payload.data
		};
	},
	[SET_UPDATE_AUXMODEL]: (state, payload) => {
		state.auxModel = {
			...state.auxModel,
			[payload.field]: payload.data
		};
	}
};
