<template>
	<v-card
		:color="color"
		:elevation="elevation"
		width="100%"
		class="rounded-t-lg"
		@click="$emit('click')"
	>
		<v-row dense>
			<v-col cols="6" class="d-flex flex-row">
				<v-icon class="mx-2 material-icons-outlined" :color="iconColor"
					>event</v-icon
				>
				<v-card-text class="ml-n3 my-n3">
					<div class="primary--text body-2">
						{{ dateTitle }}
					</div>
					<div class="body-2 font-weight-medium">
						{{ dateFormated }}
					</div>
				</v-card-text>
			</v-col>
			<v-col cols="6" class="d-flex flex-row">
				<v-icon class="mx-2 material-icons-outlined" :color="iconColor"
					>schedule</v-icon
				>
				<v-card-text class="ml-n3 my-n3">
					<div class="primary--text body-2">
						{{ timeTitle }}
					</div>
					<div class="body-2 font-weight-medium">
						{{ timeFormated }}
					</div>
				</v-card-text>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import dayjs from "dayjs";
import { FORMAT_DATE, FORMAT_TIME_A } from "@/helpers/constants";
export default {
	props: {
		dateTitle: String,
		timeTitle: String,
		date: String,
		elevation: {
			type: String,
			default: "4"
		},
		color: String,
		iconColor: {
			type: String,
			default: "secondary"
		}
	},
	computed: {
		dateFormated() {
			if (!this.date) return "";
			return dayjs(this.date).format(FORMAT_DATE);
		},
		timeFormated() {
			if (!this.date) return "";
			return dayjs(this.date).format(FORMAT_TIME_A);
		}
	}
};
</script>
