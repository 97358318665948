import { LANGUAGE } from "./constants";
import i18n from "../../../i18n";
import vuetify from "@/plugins/vuetify";
export default {
	_changeLang: async ({ commit }, lang) => {
		commit(LANGUAGE, lang);
		localStorage.setItem(LANGUAGE, lang);
		vuetify.framework.lang.current = lang;
		i18n.locale = lang;
	},
	_loadClientLang: async ({ dispatch }) => {
		let lang = localStorage.getItem(LANGUAGE);
		if (!lang) {
			const ln = navigator.language || navigator.userLanguage;
			if (ln === "en") {
				lang = "en";
			} else if (ln === "es-419") {
				lang = "es";
			} else {
				lang = "en";
			}
		}
		dispatch("_changeLang", lang);
	}
};
