import Market from "@/views/market/MarketIndex";
import ProductDetail from "@/views/market/ProductDetail/ProductDetail";
import PaymentProducts from "@/views/market/PaymentProducts";
import PaymentSuccess from "@/views/market/PaymentSuccess";

export default [
	{
		path: "/market",
		name: "Market",
		component: Market,
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: ":id",
				name: "Product detail",
				component: ProductDetail
			},
			{
				path: ":id/payment",
				name: "Payment",
				component: PaymentProducts
			},
			{
				path: ":id/payment/success",
				name: "Success",
				component: PaymentSuccess
			}
		]
	}
];
