import axios from "axios";
import store from "@/store";

const instance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	headers: {
		"Content-Type": "application/json"
	}
});

instance.interceptors.request.use(
	config => {
		const token = store.state.auth.token;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		} else {
			delete config.headers.common.Authorization;
		}
		return config;
	},

	error => Promise.reject(error)
);
export default instance;
