import Pusher from "pusher-js";
import store from "@/store";
export default {
	getInstance() {
		return new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
			cluster: "us3",
			authEndpoint: process.env.VUE_APP_BASE_URL + "/broadcasting/auth",
			auth: {
				headers: {
					accept: "application/json",
					authorization: "Bearer " + store.state.auth.token || ""
				}
			}
		});
	}
};
