export const SERVICE = "SERVICE";
export const SET_REFERENCE = "SET_REFERENCE";
export const SET_MODEL = "SET_MODEL";
export const SET_SOURCE = "SET_SOURCE";
export const SET_AUXMODEL = "SET_AUXMODEL";
export const SET_UPDATE_MODEL = "SET_UPDATE_MODEL";
export const SET_UPDATE_AUXMODEL = "SET_UPDATE_AUXMODEL";
export const SET_RECOMMENDED = "SET_RECOMMENDED";
export const RESET_STATE = "RESET_STATE"

export const initialState = () => ({
	id: -1,
	reservation: {
		id: -1,
		editToken: null,
		client: {
			id: null,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			lang: null,
			fullName: null
		},
		adults: null,
		children: null,
		infants: null
	},
	pickup: null,
	dropoff: null,
	pickupDatetime: null,
	flightNumber: null,
	flightDatetime: null,
	serviceType: {
		id: -1,
		code: null,
		name: null,
		maxPax: null,
		maxSuitecases: null,
		category: null,
		score: null,
		suitecasesDescription: null,
		description: null
	},
	transportUnit: {
		id: -1,
		image: "",
		maxPassengers: 0,
		name: ""
	},
	driver: {
		firstName: "",
		id: -1,
		image: "",
		lastName: ""
	},
	trackingStatus: "pending",
	salesChannel: {
		id: null,
		code: null,
		name: null,
		ratePlanId: null,
		description: null,
		status: null
	},
	salesChannelReference: null,
	trip: null,
	flightValidationStatus: null,
	flightValidationData: null,
	checkinStatus: "pending",
	travelTime: null,
	travelLength: null,
	clientNote: null
});
