<template>
	<div>
		<div class="primary--text subtitle-2 ml-2">{{ title }}</div>
		<MazPhoneNumberInput
			:disabled="disabled"
			placeholder=" "
			ref="phonefield"
			no-example
			@update="update"
			:value="value"
			@input="input"
		/>
		<div class="ml-3 red--text caption" v-if="!isValid">
			{{ this.$t("global.required") }}
		</div>
		<div class="ml-3 caption" v-else>
			{{ this.$t("global.neverShare") }}
		</div>
	</div>
</template>

<script>
import { MazPhoneNumberInput } from "maz-ui";
export default {
	components: {
		MazPhoneNumberInput
	},
	props: {
		value: String,
		title: String,
		disabled: Boolean,

		rules: {
			type: Array,
			default: function() {
				return [v => !!v || this.$t("global.required")];
			}
		}
	},
	data: () => ({
		isValid: true
	}),
	mounted() {
		this.$refs.phonefield.asYouTypeNumber = this.value;
	},
	methods: {
		validate() {
			this.isValid = this.$refs.phonefield.isValid;
			return this.isValid;
		},
		update(e) {
			if (this.value?.length > 0) this.isValid = true;
			this.$emit("update", e);
		},
		input(e) {
			this.$emit("input", e);
		}
	}
};
</script>
