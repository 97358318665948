export const SET_MODEL = "SET_MODEL";

export const initialState = () => ({
	id: -1,
	editToken: null,
	client: {
		id: -1,
		firstName: null,
		lastName: null,
		email: null,
		phone: null,
		lang: null,
		fullName: null
	},
	additionalServices: [],
	adults: 0,
	children: 0,
	infants: 0,
	transportServices: []
});
