import { SET_INTERCOM } from "./constants";
import chatHelper from "@/service/helpers-chat";
export default {
	_initIntercom: async ({ commit, rootState }) => {
		const reservation =
			rootState.reservation.reservationModel.id != -1
				? rootState.reservation.reservationModel
				: rootState.service.checkinModel.reservation;
		chatHelper.shutdown();
		let name = reservation.client.fullName;
		let email = reservation.client.email;
		let resId = reservation.id;
		let userId = reservation.client.id;
		chatHelper.identify({
			userId: userId,
			reservation_id: resId,
			name,
			email,
			created_at: new Date().getTime() / 1000
		});
		commit(SET_INTERCOM, true);
	},
	_hideIntercomLauncher: async () => {
		chatHelper.shutdown();
	},
	_showIntercomLauncher: async () => {
		chatHelper.openChat();
	}
};
