<template>
	<div>
		<GoogleMapLoader v-slot="{ google, map }" class="travel-map">
			<MapDirectionHandler :google="google" :map="map" />
		</GoogleMapLoader>

		<div class="d-flex flex-row">
			<v-spacer></v-spacer>
			<div class="d-flex flex-row primary--text font-weight-medium">
				<div class="mr-9 ">
					<v-icon color="primary" class="mr-2 mb-2"
						>directions_car</v-icon
					>{{ formatDecimal(auxModel.travelLength) }} km
				</div>
				<div>
					<v-icon color="primary" class="mr-2 mb-2">schedule</v-icon
					>{{ formatDecimal(auxModel.travelTime) }} min
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GoogleMapLoader from "./GoogleMapLoader";
import MapDirectionHandler from "./MapDirectionHandler";
import { mapState } from "vuex";
export default {
	components: {
		GoogleMapLoader,
		MapDirectionHandler
	},
	computed: {
		...mapState("service", ["auxModel"])
	},
	methods: {
		formatDecimal: value => Number(value)?.toFixed(0) || 0
	}
};
</script>
<style lang="scss" scoped>
.travel-map {
	position: relative;
	height: 200px;
	z-index: 0;
}
</style>
