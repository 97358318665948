<template>
	<div class="fill-height d-flex flex-column align-center background-start white">
		<AppBar
			:to-page="isTravelers ? toReservation : undefined"
			:color="config.color"
		/>

		<div class="flex text-center mb-2 title font-weight-bold">
			{{ t("hello", { name: clientName }) }}
		</div>
		<v-img
			class="mb-n10"
			max-width="290px"
			width="100%"
			height="auto"
			:src="require('@/assets/homeIlustration.png')"
		></v-img>

		<v-container
			class="fill-height d-flex flex-column align-center justify-space-between  mt-16"
		>
			<v-card-text class="text-center body-2 mt-n2">
				<div class="font-weight-bold">{{ t("firstText") }}</div>
				<div>{{ t("secondText") }}</div>
			</v-card-text>
			<v-btn
				class="font-weight-bold"
				color="primary"
				rounded
				min-width="125px"
				@click="start()"
				>{{ t("btnStart") }}</v-btn
			>
			<v-container class="d-flex flex-column align-center">
				<div class="secondary--text font-weight-medium mb-1">
					{{ t("infoTitle") }}
				</div>
         <v-btn
          v-if="isIataCun"
					class="font-weight-bold"
					text
					color="primary"
					@click="openCancun()"
					>Cancun</v-btn
				>
				<v-btn
					class="font-weight-bold"
					text
					color="primary"
					@click="openArrival()"
					>{{ t("btnArrival") }}</v-btn
				>
				<v-btn
					class="font-weight-bold mb-n1"
					text
					color="primary"
					@click="openCovid()"
					>{{ t("btnCovid") }}</v-btn
				>
			</v-container>
		</v-container>
		<DialogArrival v-model="dialog.arrival" />
		<DialogCovid v-model="dialog.covid" />
    <DialogCancun v-model="dialog.cancun" />
	</div>
</template>

<script>
import AppBar from "@/components/AppBar";
import DialogArrival from "@/components/dialog/DialogArrival";
import DialogCovid from "@/components/dialog/DialogCovid";
import DialogCancun from "@/components/dialog/DialogCancun";
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "@/helpers/mapFields";

export default {
	components: {
    DialogCancun,
		DialogCovid,
		DialogArrival,
		AppBar
	},
	data: () => ({
		dialog: {
      cancun: false,
			arrival: false,
			covid: false
		},
		config: {
			color: "primary40"
		}
	}),
	computed: {
		...mapFields({
			fields: ["checkinStatus"],
			base: "checkinModel",
			mutation: "SET_UPDATE_MODEL",
			module: "service"
		}),
		...mapState("global", ["platform"]),
		...mapGetters("service", ["getBasePath", "closedMode", "isIataCun"]),
		...mapState("service", ["checkinModel"]),
		clientName: vm => vm.checkinModel.reservation?.client?.firstName || "",
		toReservation: vm => "/?token=" + vm.$route.query.token,
		isTravelers: vm =>
			vm.$route.query?.utm_source == "travelers" || vm.platform != "web"
	},
  watch: {
  },
	methods: {
		...mapActions("service", ["_updateService"]),
		openArrival() {
			this.dialog.arrival = true;
		},
		openCovid() {
			this.dialog.covid = true;
		},
    openCancun() {
      this.dialog.cancun = true;
    },
		start() {
			if (this.closedMode)
				return this.$router.push(this.getBasePath("/closed"));

			this._updateService({ checkinStatus: "started" }).then(() => {
				this.checkinStatus = "started";
				this.$router.push(this.getBasePath("/reservation"));
			});
		},
		t(key, options = {}) {
			return this.$t(`global.${key}`, options);
		}
	}
};
</script>

<style lang="scss" scoped>
.background-start {
	background-image: url("~@/assets/startBackground.svg");
	background-attachment: initial;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% 40%;
}

</style>
