import { SET_PRODUCTS, SET_MODEL, initialState } from "./constants";
import { getCurrencyWithLang } from "@/helpers/market";
import cloneDeep from "lodash/cloneDeep";
import API from "@/service/api-loader.service";

export default {
	_fetchProducts: async ({ commit, rootState }) => {
		const currency = getCurrencyWithLang();
		const reservation = rootState.reservation.reservationModel;
		let iata = null;
		reservation?.transportServices?.find(service => {
			iata = service.dropoff?.iata || service.pickup?.iata;
			if (iata) return;
		});
		if (iata) {
			await API.additionalServices
				.get({
					lang: rootState.lang.lang,
					iata: iata.toLowerCase(),
					currency
				})
				.then(response => {
					commit(SET_PRODUCTS, response);
					commit("updateField", {
						path: "model.iata",
						value: iata
					});
				});
		}
	},
	_resetModel: async ({ commit }) => {
		commit(SET_MODEL, initialState());
	},
	_getProductById: async (
		{ commit, dispatch, state, rootState },
		{ id, refresh }
	) => {
		if (state.products.length === 0) {
			// Si no hay productos, se solicita la reservacion
			// para obtener la lista de productos asignados al IATA de la reserva,
			// y de ahi filtrar el producto
			await dispatch("reservation/_fetchReservation", null, {
				root: true
			});
		}
		if (refresh) {
			await dispatch("_fetchProducts");
		}

		const currency = getCurrencyWithLang();
		const product = state.products.find(item => item.id === Number(id));

		if (!refresh) {
			let model = {
				...initialState(),
				iata: state.model.iata,
				name: product.name,
				additionalServiceTypeId: product.id,
				reservationId: rootState.reservation.reservationModel.id,
				bookingTotal: {
					total: 0,
					currency: currency
				}
			};
			const prices = product.prices.map(price => ({
				category: price.category,
				price: price.price,
				quantity: 0
			}));

			if (product.relatedTo === "TransportServices") {
				rootState.reservation.reservationModel.transportServices.forEach(
					service => {
						model.rates.push({
							transportServiceId: service.id,
							serviceNumber: service.serviceNumber,
							disabled: !product.availableFor.includes(
								service.serviceNumber
							),
							total: 0,
							currency: currency,
							prices: cloneDeep(prices)
						});
					}
				);
			} else {
				model.rates.push({
					transportServiceId: null,
					total: 0,
					currency: currency,
					prices: cloneDeep(prices)
				});
			}

			commit("updateField", {
				path: "model",
				value: model
			});
		}
		return product;
	}
};
