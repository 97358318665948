<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-container class="d-flex flex-column align-center">
			<v-form ref="form">
				<v-row dense>
					<v-col cols="12">
						<TextField
							:disabled="inputDisable"
							@focus="openSearch('pickup')"
							prepend-inner-icon="hail"
							v-model="pickupName"
							:placeholder="t('form.pickup.placeholder')"
							:label="t('form.pickup.label')"
							:messages="t('form.pickup.message')"
							:title="`${t('form.pickup.location')}*`"
						/>
					</v-col>
					<v-col cols="12">
						<TextField
							:disabled="inputDisable"
							@focus="openSearch('dropoff')"
							prepend-inner-icon="location_on"
							v-model="dropoffName"
							:placeholder="t('form.dropOff.placeholder')"
							:label="t('form.dropOff.label')"
							:messages="t('form.dropOff.message')"
							:title="`${t('form.dropOff.location')}*`"
						/>
					</v-col>
					<v-col cols="12">
						<GoogleMapLoader
							v-slot="{ google, map }"
							class="travel-map"
						>
							<MapDirection :google="google" :map="map" />
						</GoogleMapLoader>
					</v-col>
					<v-col cols="12" class="d-flex flex-row">
						<v-spacer></v-spacer>
						<div
							class="d-flex flex-row primary--text font-weight-medium"
						>
							<div class="mr-9 ">
								<v-icon color="primary" class="mr-2 mb-2"
									>directions_car</v-icon
								>{{ formatDecimal(auxModel.travelLength) }} km
							</div>
							<div>
								<v-icon color="primary" class="mr-2 mb-2"
									>schedule</v-icon
								>{{ formatDecimal(auxModel.travelTime) }} min
							</div>
						</div>
					</v-col>
				</v-row>
			</v-form>
		</v-container>
		<v-container class="mb-3">
			<LayoutBtn
				@click-edit="cancel()"
				@click-confirm="save()"
				:disabled-confirm="inputDisable"
				:edit="$t('global.btnCancel')"
				:confirm="$t('global.btnConfirm')"
			/>
		</v-container>
	</div>
</template>

<script>
import LayoutBtn from "@/layouts/LayoutBtn";
import TextField from "@/components/field/TextField";
import GoogleMapLoader from "@/components/map/GoogleMapLoader";
import MapDirection from "./MapDirection";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
	components: {
		MapDirection,
		TextField,
		LayoutBtn,
		GoogleMapLoader
	},
	computed: {
		...mapGetters("service", ["getBasePath", "inputDisable"]),
		...mapState("service", ["auxModel", "checkinModel"]),
		pickupName: vm => vm.auxModel?.pickup?.name || null,
		dropoffName: vm => vm.auxModel?.dropoff?.name || null
	},
	activated() {
		this.$refs.form.resetValidation();
	},
	methods: {
		...mapMutations("service", ["SET_MODEL", "SET_AUXMODEL"]),
		save() {
			if (!this.$refs.form.validate()) return;
			this.SET_MODEL(this.auxModel);
			this.back();
		},
		back() {
			this.$router.replace(this.getBasePath(this.$route.meta.back));
		},
		cancel() {
			this.SET_AUXMODEL(this.checkinModel);
			this.back();
		},
		formatDecimal: value => {
			if (value) return Number(value)?.toFixed(0);
			return 0;
		},
		openSearch(mode) {
			this.$router.push(this.getBasePath("/form/location/" + mode));
		},
		t(key, options = {}) {
			return this.$t(`section.pickupDropOff.${key}`, options);
		}
	}
};
</script>

<style lang="scss" scoped>
.travel-map {
	position: relative;
	height: 200px;
	z-index: 0;
}
</style>
