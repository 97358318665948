<template>
	<v-dialog max-width="400" :value="value" @input="close">
		<v-card min-height="290px" class=" d-flex flex-column  align-center">
			<BtnClose @click="close" />
			<v-card-title class="mt-2">
				<v-icon
					size="60"
					class="material-icons-outlined"
					color="primary"
					>support_agent</v-icon
				>
			</v-card-title>
			<v-container>
				<slot>
					<div class="font-weight-bold text-center">
						{{ t("title") }}
					</div>
				</slot>
			</v-container>

			<ShuttleCentralContact @chat="close" />
		</v-card>
	</v-dialog>
</template>

<script>
import ShuttleCentralContact from "@/components/ShuttleCentralContact";
import BtnClose from "@/components/btn/BtnClose";
export default {
	components: {
		BtnClose,
		ShuttleCentralContact
	},
	props: {
		value: Boolean
	},
	methods: {
		t(key, options = {}) {
			return this.$t(`info.help.${key}`, options);
		},
		close() {
			this.$emit("input", false);
			this.$emit("close")
		}
	}
};
</script>
