const chatVisibility = visibility => {
	let count = 0;
	try {
		if (window.Atlas.chat) {
			const chat = document.getElementById("atlas-chat");
			chat.style.visibility = visibility;
		} else {
			throw "e";
		}
	} catch (error) {
		if (count < 10) {
			setTimeout(() => {
				count += count;
				chatVisibility(visibility);
			}, 5000);
		} else {
			throw error;
		}
	}
};

export default {
	shutdown() {
		chatVisibility("hidden");
	},
	identify(params) {
		window.Atlas.identify(params);
	},
	start() {
		chatVisibility("visible");
	},
	openChat() {
		const chat = document.getElementById("atlas-chat");
		const btn = chat.getElementsByClassName("bubble")[0];
		chat.style.visibility = "visible";
		btn.click();
		setTimeout(() => {
			const constrols = chat.getElementsByClassName("controls")[0];
			const btnClose = constrols.getElementsByTagName("button")[0];
			btnClose.addEventListener("click", function() {
				chat.style.visibility = "hidden";
			});
		}, 1000);
	}
};
