<template>
	<v-container
		style="height: 140px"
		class="d-flex flex-column  align-center subtitle-2 justify-space-between mb-2"
	>
		<div>{{ t("contact") }}</div>
			<a href="tel:+17867236220" :class="style">Call center: +17867236220</a>
			<a href="mailto:ebookings@shuttlecentral.com" :class="style"
			>Mail: ebookings@shuttlecentral.com</a
		>
		<div :class="style" @click="chat">Chat</div>
	</v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
	data: () => ({
		style: "font-weight-medium text-decoration-underline primary--text"
	}),
	methods: {
		...mapActions("global", ["_showIntercomLauncher"]),
		chat() {
			this._showIntercomLauncher();
			this.$emit("chat");
		},
		t(key, options = {}) {
			return this.$t(`info.help.${key}`, options);
		}
	}
};
</script>
