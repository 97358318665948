export default [
	{
		path: "/login",
		component: () => import("@/views/LoginUser"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "*",
		component: () => import("@/views/LoginUser")
	}
];
