var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-container',[_c('span',{staticClass:"title font-weight-bold"},[_vm._v(_vm._s(_vm.t("title")))]),_c('v-divider',{staticClass:"mt-2"}),_vm._l((_vm.details),function(detail,i){return _c('div',{key:i,staticClass:"d-flex flex-row justify-space-between mt-4"},[_c('span',{staticClass:"title font-weight-normal subtitle-2"},[_vm._v(_vm._s(detail.text))]),_c('span',{staticClass:"title font-weight-bold subtitle-2"},[_vm._v(_vm._s(_vm.marketModel.bookingTotal.currency)+" $"+_vm._s(detail.value))])])}),_c('v-divider',{staticClass:"mt-2"})],2),_c('v-container',[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('TextField',{staticClass:"atlas-mask data-hj-suppress",attrs:{"title":_vm.t('holder')},model:{value:(_vm.model.cardHolder),callback:function ($$v) {_vm.$set(_vm.model, "cardHolder", $$v)},expression:"model.cardHolder"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('TextField',{staticClass:"atlas-mask data-hj-suppress",attrs:{"title":_vm.t('number')},scopedSlots:_vm._u([(
									_vm.cardConfig &&
										[
											'visa',
											'mastercard',
											'american-express',
											'discover'
										].includes(_vm.cardConfig.type)
								)?{key:"append",fn:function(){return [_c('div',{class:("credit-card-type " + (_vm.cardConfig.type))})]},proxy:true}:null],null,true),model:{value:(_vm.cardNumber),callback:function ($$v) {_vm.cardNumber=$$v},expression:"cardNumber"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:('##/####'),expression:"'##/####'"}],staticClass:"atlas-mask data-hj-suppress",attrs:{"rules":_vm.rules.cardDate,"type":"number","title":_vm.t('date')},model:{value:(_vm.model.cardDate),callback:function ($$v) {_vm.$set(_vm.model, "cardDate", $$v)},expression:"model.cardDate"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('TextField',{staticClass:"atlas-mask data-hj-suppress",attrs:{"title":_vm.t('cvv')},model:{value:(_vm.model.cvv),callback:function ($$v) {_vm.$set(_vm.model, "cvv", $$v)},expression:"model.cvv"}})],1)],1)],1)],1)],1),_c('v-footer',{staticClass:"max-width-viewport-container",attrs:{"color":"white","elevation":"3","app":""}},[_c('v-container',{staticClass:"d-flex flex-row justify-space-between"},[_c('v-btn',{staticClass:"text-none font-weight-bold",attrs:{"elevation":"0","block":"","color":"primary","rounded":""},on:{"click":_vm.checkout}},[_c('span',{staticClass:"mx-3"},[_vm._v(_vm._s(_vm.t("btnPay")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }