<template>
  <v-card
    class="card-product"
    min-width="100%"
    max-width="170px"
    elevation="0"
    min-height="190px"
    @click="$emit('click')"
  >
    <img loading="lazy" :src="getBackground" class="img-product" />
    <div class="text-start px-3 pb-2 d-flex flex-column justify-center card-text">
      <span class="font-weight-normal">{{ getTitle }}</span>
      <span class="font-weight-bold"> {{ t("from") }} ${{ getPriceFrom }} <span class="text-uppercase">{{item.priceCurrency}}</span></span>
    </div>
  </v-card>
</template>

<script>
import { getPrice, getIcon } from "@/helpers/market";
export default {
  props: {
    item: Object,
    title: String,
    price: Number
  },
  computed: {
    getTitle() {
      if (this.item.content.name.length <= 30) {
        return this.item.content.name;
      }
      return `${this.item.content.name.substring(0, 27)}...`;
    },
    getClass() {
      if (this.getBackground) {
        return {
          price: "white--text",
          title: "white--text"
        };
      } else {
        return {
          price: "primary--text",
          title: "black--text"
        };
      }
    },
    loadIcon() {
      return getIcon(this.item.code);
    },
    getBackground() {
      return this.item.content.image;
    },
    getPriceFrom() {
      return getPrice(this.item.prices);
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`market.product.${key}`, options);
    }
  }
};
</script>
<style scoped>
.card-product {
  box-shadow: 1px 2px 12px rgba(204, 210, 216, 0.8) !important;
  border-radius: 8px 8px 8px 8px;
}
.card-text {
  min-height: 90px;
}
.img-product {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 110px;
  min-height: 110px;
  height: auto;
}
</style>
