import i18n from "@/i18n";
import store from "@/store";

export const getCurrencyWithLang = () =>
	store.state.lang.lang === "es" ? "MXN" : "USD";

export const getPrice = pricesList => {
	if (pricesList.length === 1) return pricesList[0].price;
	if (pricesList.length > 1) {
		const adult = pricesList.find(({ category }) => category === "adult");
		if (adult) return adult.price;
		return getLowerPrice(pricesList).price;
	}
	return;
};

export const getCategoryPrice = pricesList => {
	if (pricesList.length === 1) return pricesList[0].category;
	if (pricesList.length > 1) {
		const adult = pricesList.find(({ category }) => category === "adult");
		if (adult) return adult.category;
		return getLowerPrice(pricesList).category;
	}
	return;
};

export const getIcon = icon => {
	try {
		return require(`@/assets/products/${icon}.png`);
	} catch (error) {
		return require(`@/assets/products/other.png`);
	}
};

const getLowerPrice = pricesList => {
	const aux = JSON.parse(JSON.stringify(pricesList));
	const listOrdered = aux.sort((a, b) => (a.price >= b.price ? 1 : -1));
	return listOrdered ? listOrdered[0] : {};
};

export const getTexts = category => {
	const categories = ["adult", "child", "infant"];
	if (categories.includes(category)) {
		return {
			title: i18n.t(`market.product.detail.price.${category}.title`),
			subtitle: i18n.t(`market.product.detail.price.${category}.subtitle`)
		};
	}
	return {
		title: i18n.t(`market.product.detail.price.generic.title`),
		subtitle: ""
	};
};
