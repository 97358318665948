<template>
	<v-dialog max-width="300px" v-model="$root.notification.show">
		<v-card
			min-height="200px"
			class=" d-flex flex-column justify-space-around  align-center"
		>
			<BtnClose @click="close" />
			<v-card-title class="mt-2">
				<v-icon
					:color="color"
					class="material-icons-outlined"
					size="60"
					>{{ $root.notification.icon || icon }}</v-icon
				>
			</v-card-title>
			<v-card-subtitle
				class="font-weight-black  mt-3 mx-12 flex text-center "
			>
				{{ $root.notification.title }}
			</v-card-subtitle>
			<v-card-text
				class="font-weight-medium body-2 mx-12 flex text-center"
			>
				{{ $root.notification.description }}
			</v-card-text>
			<v-container
				class="d-flex justify-center"
				v-if="$root.notification.btn"
			>
				<v-btn
					text
					color="primary"
					class="font-weight-bold"
					@click="close()"
					>{{ $t("global.btnGotIt") }}</v-btn
				>
			</v-container>
      <ShuttleCentralContact v-if="this.$root.notification.contact" />
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
import ShuttleCentralContact from "@/components/ShuttleCentralContact";
export default {
	components: {
		BtnClose,
    ShuttleCentralContact
	},
	data: () => ({
		notification: {
			icon: null,
			closeAutomatic: true,
			type: "info",
			show: false,
			title: null,
			description: null,
      contact: false,
			btn: null
		}
	}),
	watch: {
		show(v) {
			if (v)
				if (this.$root.notification.closeAutomatic)
					setTimeout(() => this.close(), 7000);
		}
	},
	computed: {
		show: vm => vm.$root.notification.show,
		icon: vm => {
			switch (vm.$root.notification.type) {
				case "info":
					return "info";
				case "error":
					return "error_outline";
				case "success":
					return "check_circle";
				default:
					return "error_outline";
			}
		},
		color: vm => {
			switch (vm.$root.notification.type) {
				case "info":
					return "primary";
				case "error":
					return "red";
				case "success":
					return "#46CF39";
				default:
					return "primary";
			}
		}
	},

	methods: {
		close() {
			this.$root.notification.show = false;
			this.$root.notification = JSON.parse(
				JSON.stringify(this.notification)
			);
		},
		t(key, options = {}) {
			return this.$t(`info.${key}`, options);
		}
	}
};
</script>
