import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);
const colors = {
	primary: "#4F39CF",
	secondary: "#F7BB7F",
	success: "#46CF39",
	bluegrey: "#9AA5B2",
	primary40: "#F5F4FF",
	primary20: "#FAF9FF",
	primary100: "#E5E3FF"
};
export default new Vuetify({
	lang: {
		locales: { es },
		current: "es"
	},
	icons: {
		iconfont: "md"
	},
	theme: {
		themes: {
			light: colors,
			dark: colors
		}
	}
});
